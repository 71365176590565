import './LandingPagePersonas.scss'

const LandingPagePersonas = () => {
  return (
    <div className='LandingPagePersonas'>

      <div
        className='' style={{
          backgroundImage: "url('/images/landing-lean-agile-foundation/noNoisePersonasLp.png')",
          backgroundRepeat: 'no-repeat',
          minHeight: 'max(41.6vw, 350px)',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          opacity: '1'
        }}
      >
        <div className='container px-4'>
          <div className='row mt-5'>
            <div className='' data-wow-delay='0.3s'>

              <h2 className='text-center'>
                <span className='white persona-title'>PARA QUEM É O TREINAMENTO</span> <br />
                <span className='blue persona-title'>LEAN E AGILE FOUNDATION?</span>
              </h2>
            </div>
          </div>

          <div className='row mt-5 white personas'>
            <span>{'< Executivos e Empresários />'}</span>
            <span>{'< Gerentes de Produto ou Projeto />'}</span>
            <span>{'< Engenheiro de Software e Devops />'}</span>
            <span>{'< Agile Coaches e Scrum Masters />'}</span>
          </div>

          <div className='row orange mt-5'>
            <h1 className='head-item orange'>QUE ALMEJAM</h1>
          </div>

          <div className='row text-start'>
            <div className='col-12 col-md-6 mt-4 p-md-4'>
              <span className='d-inline-flex align-items-center'>
                <h1 className='head-item'>1.</h1>
                <h1 className='blue ms-2 head-sentence'>GERAR VALOR<br /> MAIS RÁPIDO</h1>
              </span>
            </div>
            <div className='col-12 col-md-6 mt-4 p-md-4'>
              <span className='d-inline-flex align-items-center'>
                <h1 className='head-item'>2.</h1>
                <h1 className='blue ms-2 head-sentence '>ACABAR COM O <br />DESPERDÍCIO</h1>
              </span>
            </div>
            <div className='col-12 col-md-6 mt-4 p-md-4'>
              <span className='d-inline-flex align-items-center'>
                <h1 className='head-item'>3.</h1>
                <h1 className='blue ms-2 head-sentence'>ENTENDER A ESSÊNCIA DO LEAN E DO AGILE</h1>
              </span>
            </div>
            <div className='col-12 col-md-6 mt-4 p-md-4'>
              <span className='d-inline-flex align-items-center'>
                <h1 className='head-item'>4.</h1>
                <h1 className='blue ms-2 head-sentence'>TRANSFORMAR A SUA ORGANIZAÇÃO</h1>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPagePersonas
