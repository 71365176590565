import './LandingPageContent.scss'

const LandingPageContent = () => {
  return (
    <div className='LandingPageContent'>

      <div
        className='' style={{
          // backgroundImage: "url('/images/landing-lean-agile-foundation/contentLp.png')",
          backgroundRepeat: 'no-repeat',
          minHeight: 'max(41.6vw, 350px)',
          backgroundSize: 'cover',
          backgroundPosition: '20% center',
          backgroundColor: '#05080C',
          opacity: '1'
        }}
      >
        <div className='container mt-5'>
          <div className='row'>
            <h1 className='section-title orange'>
              Dentro do treinamento, você terá acesso a:
            </h1>
          </div>

          <div className='row h-100'>
            <div className='text-base-start text-center blue mt-5 mb-5 h-100'>

              <div>
                <span
                  className='blue'
                  data-bs-toggle='collapse'
                  href='#content-1'
                  role='button'
                  aria-expanded='false'
                  aria-controls='collapseExample'
                >
                  <h1 className='content-element'>

                    {'< '}
                    <span className='white'>
                      Mais de 4 horas de conteúdo
                    </span>
                    {' />'}

                  </h1>
                </span>

                <div className='collapse mt-3 h5 px-3' id='content-1'>
                  <div className='white'>
                    <span className='orange'>{'> '}</span> Introdução a Pirâmide Lean <br />
                    <span className='orange'>{'> '}</span> Por que Lean e Agile <br />
                    <span className='orange'>{'> '}</span> Príncipios do Desperdício <br />
                    <span className='orange'>{'> '}</span> História do Lean <br />
                    <span className='orange'>{'> '}</span> Por dentro do Pensamento Sistêmico <br />
                    <span className='orange'>{'> '}</span> Gestão de Qualidade <br />
                    <span className='orange'>{'> '}</span> Gestão do Conhecimento <br />
                    <span className='orange'>{'> '}</span> Gestão de Fluxo <br />
                    <span className='orange'>{'> '}</span> Explicando os Métodos Ágeis <br />
                  </div>
                </div>
              </div>

              <div>
                <span
                  className='blue'
                  data-bs-toggle='collapse'
                  href='#content-2'
                  role='button'
                  aria-expanded='false'
                  aria-controls='collapseExample'
                >
                  <h1 className='content-element pt-5'>
                    {'< '}
                    <span className='white'>
                      Um ano de acesso ao treinamento
                    </span>
                    {' />'}
                  </h1>
                </span>

                <div className='collapse mt-3 h5 px-3' id='content-2'>
                  <div className='white'>
                    <span className='orange'>{'> '}</span> Você terá acesso a um ano e direito a todas as atualizações e bônus acrescentados nesse período.
                  </div>
                </div>
              </div>

              <div>
                <span
                  className='blue'
                  data-bs-toggle='collapse'
                  href='#content-3'
                  role='button'
                  aria-expanded='false'
                  aria-controls='collapseExample'
                >
                  <h1 className='content-element pt-5'>

                    {'< '}
                    <span className='white'>
                      Material transcrito em PDF para reforço
                    </span>
                    {' />'}
                  </h1>
                </span>

                <div className='collapse mt-3 h5 px-3' id='content-3'>
                  <div className='white'>
                    <span className='orange'>{'>  '}</span> Material robusto para revisar caso não tenha tempo para reassistir as aulas, sendo um material transcrito de cada aula para complementar o seu estudo.
                  </div>
                </div>
              </div>

              <div>
                <span
                  className='blue'
                  data-bs-toggle='collapse'
                  href='#content-4'
                  role='button'
                  aria-expanded='false'
                  aria-controls='collapseExample'
                >
                  <h1 className='content-element pt-5'>

                    {'< '}
                    <span className='white'>
                      Fórum interativo para tirar suas dúvidas
                    </span>
                    {' />'}

                  </h1>
                </span>

                <div className='collapse mt-3 h5 px-3' id='content-4'>
                  <div className='white'>
                    <span className='orange'>{'> '}</span> Caso tenha qualquer dúvida, poderá interagir comigo, com a nossa equipe e com outros alunos para agilizar o seu conhecimento.
                  </div>
                </div>
              </div>

              <div>
                <span
                  className='blue'
                  data-bs-toggle='collapse'
                  href='#content-5'
                  role='button'
                  aria-expanded='false'
                  aria-controls='collapseExample'
                >
                  <h1 className='content-element pt-5'>

                    {'< '}
                    <span className='white'>
                      Certificado de conclusão
                    </span>
                    {' />'}

                  </h1>
                </span>

                <div className='collapse mt-3 h5 px-3' id='content-5'>
                  <div className='white'>
                    <span className='orange'>{'> '}</span>Após concluir o treinamento,  cumprindo com os quizzes você poderá emitir certificado de conclusão Lean and Agile Foundation.
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPageContent
