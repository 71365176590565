import { useTranslation } from 'react-i18next'
import '../../input.css'

const Card = ({ title, content, id }) => {
  return (
    <div
      id={`Start-page-card-${id}`}
      className=' h-full z-10'
    >
      <div className='flex flex-col bg-steelGray rounded-3xl h-full w-full text-center sm:px-9 sm:py-8 p-6'>
        <span className='font-bold text-xl'>
          {title}
        </span>
        <hr className='flex self-center bg-gray-300 w-full my-4' />
        <span className=' md:text-xl font-regular sm:text-left list-disc text-slate-200 '>
          {content}
        </span>
      </div>
    </div>
  )
}
const StartPage = () => {
  const [t] = useTranslation()

  const cardContent1 = (
    <div className='text-left p-2 md:leading-8'>
      <li>{t('start.card_content_1')}</li>
      <li>{t('start.card_content_2')}</li>
      <li>{t('start.card_content_3')}</li>
      <li>{t('start.card_content_4')}</li>
      <li>{t('start.card_content_5')}</li>
      <li>{t('start.card_content_6')}</li>
      <li>{t('start.card_content_7')}</li>
      <li>{t('start.card_content_8')}</li>
      <li>{t('start.card_content_9')}</li>
      <li>{t('start.card_content_10')}</li>
      <li>{t('start.card_content_11')}</li>
      <li>{t('start.card_content_12')}</li>
      <li>{t('start.card_content_13')}</li>
    </div>
  )

  const cardContent2 = (
    <span>
      {t('start.card_content_14')}
      <br />
      <br />
      {t('start.card_content_15')}
      <br />
      <br />
      {t('start.card_content_16')}
    </span>
  )

  const cardContent3 = (
    <span>
      {t('start.card_content_17')}
      <br /> <br />
      {t('start.card_content_18')}
      <br /> <br />
      {t('start.card_content_19')}
    </span>
  )

  return (
    <div
      id='Start-page'
      className='bg-ebony flex h-full flex-col py-16 z-0'
    >
      <div className='flex justify-center md:pb-16'>
        <span className='text-center md:text-5xl text-2xl font-black break-words p-3'>
          {t('start.title')}
        </span>
      </div>
      <div className='flex flex-col lg:flex-row md:px-40 sm:px-12  '>
        <div className='p-[1px] lg:w-1/3 m-3  rounded-3xl drop-shadow-xl  bg-gradient-to-r from-pyramid-19  to-gray-700'>
          <Card
            title={t('start.sofre')}
            content={cardContent1}
          />
        </div>
        <div className='p-[1px] lg:w-1/3 m-3  rounded-3xl drop-shadow-xl  bg-gradient-to-r from-pyramid-10  to-gray-700'>
          <Card
            title={t('start.lean')}
            content={cardContent2}
          />
        </div>
        <div className='p-[1px] lg:w-1/3 m-3  rounded-3xl drop-shadow-xl  bg-gradient-to-r from-pyramid-1  to-gray-700'>
          <Card
            title={t('start.metodo')}
            content={cardContent3}
          />
        </div>

      </div>
    </div>
  )
}

export default StartPage
