import './LandingPageWarranty.scss'

const LandingPageWarranty = () => {
  return (
    <div className='LandingPageWarranty'>

      <div
        className='d-flex justify-content-center align-items-center' style={{
          backgroundImage: "url('/images/landing-lean-agile-foundation/warrantyLp.png')",
          backgroundRepeat: 'no-repeat',
          minHeight: 'max(41.6vw, 350px)',
          backgroundSize: 'cover',
          backgroundPosition: '20% center',
          opacity: '1'
        }}
      >
        <div className='container px-4 p-4'>
          <div className='row'>
            <span className='head-item'>
              SEM RISCO
            </span>
            <span className='white display-4'>
              7 dias pra testar
            </span>
          </div>

          <div className='row mt-5'>
            <div className='col-md-4 col-12'>
              <img src='/images/landing-lean-agile-foundation/verified.png' alt='' width='80%' />

            </div>
            <div className='col-md-8 col-12 text-center text-base-start mt-4'>
              <h1>
                <span className='white'>
                  Você possui o direito de usar sem risco o treinamento por 7 dias e caso você sinta que não é o momento, basta enviar um email para academy@leanit101.com para solicitar o reembolso.
                </span> <br /> <br />
                <span className='white'>
                  É simples, é ágil, é Lean it 101.
                </span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPageWarranty
