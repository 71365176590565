import { useState } from 'react'
import '../../input.css'
import axios from 'axios'
import Validator from 'validator'

import { ebookSubcriptionEvent } from './FaceBookEventTracking'
const EbookPage = () => {
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [whatsapp, setWhatsapp] = useState('')
  const [ebookSent, setEbookSent] = useState(false)

  const addUserToList = async (email, name, surname, whatsapp) => {
    const data = {
      name,
      email,
      surname,
      whatsapp
    }
    if (Validator.isEmail(email)) {
      if (Validator.isMobilePhone(whatsapp.replace(/\D/g, ''))) {
        if (email && name && surname && whatsapp) {
          const response = await axios.put('/api/contacts/ebook', data)
          setEmail('')
          setName('')
          setSurname('')
          setWhatsapp('')
          response?.data?.message === 'Seu ebook está a caminho! Nos próxiimos minutos você receberá um email nosso. Verifique também na caixa de promoções e certifique-se de adicionar nosso email como contato para que você receba na sua caixa de entrada.'
            ? window.toastr.success(response?.data?.message)
            : window.toastr.error(response?.data?.message)
          setEbookSent(true)
          ebookSubcriptionEvent()
        } else {
          window.toastr.error('Preencha todos os campos por gentileza!')
        }
      } else {
        window.toastr.error('Por favor insira um whatsapp valido!')
      }
    } else {
      window.toastr.error('Por favor insira um email valido!')
    }
  }

  return (
    <div
      id='CoverPage'
      className='flex md:h-screen h-full text-white flex-col bg-gradient-radial to-steelGray via-leanit101Blue from-leanit101BlueLight4 font-Barlow'
    >
      <div className='bg-vulcan h-16 w-full flex justify-center items-center'>
        <img src='images/logo-leanit101-slogan.png' alt='Logo' className='h-2/3' />
      </div>

      <div className='flex md:flex-row flex-col  justify-between items-center h-full py-4 '>

        <div className='flex flex-col 2xl:flex-row justify-around md:px-24 px-3 w-full items-center'>

          <div className=' mt-4 flex items-center flex-col'>
            <span className='md:text-5xl text-3xl text-start font-black md:max-w-[700px] drop-shadow-2xl'>
              MAXIMIZE SEUS RESULTADOS
              COM LEAN E AGILE
            </span>
            <div className='md:w-[700px] self-center flex p-4'>
              <img src='images/ebookmockup.png' alt='' />
            </div>
          </div>
          {!ebookSent
            ? (
              <div className='flex flex-col text-left gap-3 md:w-[600px] h-fit bg-vulcan drop-shadow-2xl border-1 border-solid border-pyramid-19 rounded-xl md:p-12 p-7'>
                <span className='text-3xl font-black'>
                  O GUIA DEFINITIVO DA TRANSFORMAÇÃO LEAN AGILE
                </span>
                <span className='text-base '>
                  Este livro é fruto de duas décadas de experiência elevando a performance de empresas de diversos portes, através da implementação de métodos Lean e Agile. Ele vai te mostrar o passo a passo de como você pode melhorar seus resultados em qualquer tipo de negócio. Escrito especialmente para:
                  <br />
                  <br />

                  <ul className='font-semibold list-disc list-inside'>
                    <li>Desenvolvedores</li>
                    <li>Agile Coaches</li>
                    <li>Executivos</li>
                    <li>Gerentes</li>
                  </ul>

                  <br />
                  Preencha o formulário para receber gratuitamente sua cópia.
                </span>
                <div className='text-w'>
                  <form action='' className='flex flex-col text-white gap-4'>
                    <div className='flex flex-col font-semibold text-sm'>
                      Nome
                      <input
                        type='text' onChange={(e) => {
                          e.preventDefault()
                          setName(e.target.value)
                        }} value={name} className='rounded-md p-2 text-black mt-1' placeholder='Insira aqui o seu nome'
                      />
                    </div>
                    <div className='flex flex-col font-semibold text-sm'>
                      Sobrenome
                      <input
                        type='text' onChange={(e) => {
                          e.preventDefault()
                          setSurname(e.target.value)
                        }} value={surname} className='rounded-md p-2 text-black mt-1' placeholder='Insira aqui o seu sobrenome'
                      />
                    </div>
                    <div className='flex flex-col font-semibold text-sm'>
                      O seu melhor email
                      <input
                        type='text' onChange={(e) => {
                          e.preventDefault()
                          setEmail(e.target.value)
                        }} value={email} className='rounded-md p-2 text-black mt-1' placeholder='Insira aqui o seu melhor email'
                      />
                    </div>
                    <div className='flex flex-col font-semibold text-sm'>
                      O seu WhatsApp
                      <input
                        type='text' onChange={(e) => {
                          e.preventDefault()
                          const mask = e.target.value.replace(/\D/g, '')
                            .replace(/^(\d)/, '($1')
                            .replace(/^(\(\d{2})(\d)/, '$1) $2')
                            .replace(/(\d{4})(\d{1,5})/, '$1-$2')
                            .replace(/(-\d{5})\d+?$/, '$1')
                          setWhatsapp(mask)
                        }} value={whatsapp} className='rounded-md p-2 text-black mt-1' placeholder='Insira aqui o seu whatsapp'
                      />
                    </div>
                    <input
                      type='button'
                      className='text-white bg-cyan-600 hover:bg-cyan-500 hover:cursor-pointer font-black rounded-full w-96 mt-5 p-3 px-4 self-center'
                      value='Receber ebook'
                      onClick={() => addUserToList(email, name, surname, whatsapp)}
                    />
                  </form>
                </div>
              </div>)
            : (
              <div className='flex flex-col text-left gap-3 md:w-[600px] h-fit bg-vulcan drop-shadow-2xl border-1 border-solid border-pyramid-19 rounded-xl md:p-12 p-7'>
                <span className='text-3xl font-black'>
                  MUITO OBRIGADO!
                </span>
                <span className='text-lg py-5'>

                  Acabamos de lhe enviar um email com um link para download do seu ebook. Não esqueça de verificar a caixa de spam e a de promoção caso não receba.
                  <br />
                  <br />
                  Simplesmente devore esse ebook. Certamente ele vai te ajudar a aumentar sua performance e os resultados da sua empresa.
                  <br />
                  <br />
                  Caso você tenha interesse em dar o próximo passo no seu processo de transformação,  e aprender de maneira muito mais aprofundada sobre esses temas, clique no botão abaixo para conhecer a nossa Jornada de Transformação Lean Agile!

                  <br />
                </span>

                <button
                  className='text-white bg-cyan-600 hover:bg-cyan-500 hover:cursor-pointer font-black rounded-full w-fit p-5 text-xl px-12 self-center'
                  type='button'
                  onClick={() => {
                    window.location.href = '/'
                  }}
                >
                  QUERO CONHECER
                </button>

              </div>)}
        </div>
      </div>
    </div>
  )
}

export default EbookPage
