import { useTranslation } from 'react-i18next'
import '../../input.css'

const TeacherSection = () => {
  const [t] = useTranslation()

  return (
    <div
      id='TeacherSection'
      className='bg-vulcan flex flex-col-reverse xl:flex-row md:px-48 p-[1.5rem] gap-auto md:py-28 md:justify-between items-center'
    >
      <div className='flex flex-col text-center pt-12 xl:py-0'>
        <div className='flex flex-col pb-5 md:text-left'>
          <span className='text-4xl md:text-5xl font-extrabold'>
            {t('teacher.title')}
          </span>
          <span className='text-2xl text-cyan-600 py-2 font-thin mt-2'>
            Samuel Crescêncio
          </span>
        </div>
        <span className='text-lg md:text-left md:pr-32'>
          {t('teacher.text_1')}
          <br /> <br />
          {t('teacher.text_2')}
          <br /> <br />
          {t('teacher.text_3')}
          <br /> <br />
          {t('teacher.text_4')}
        </span>
      </div>
      <div className='rounded-xl p-[1px] bg-gradient-to-r from-pyramid-19 via-pyramid-1 to-pyramid-10 drop-shadow-xl h-fit  w-full'>
        <img src='images/samuel.jpg' alt='' className='rounded-xl ' />
      </div>
    </div>
  )
}

export default TeacherSection
