import './LandingPageVideo.scss'

const LandingPageVideo = () => {
  return (
    <div className='LandingPageVideo'>
      <div className='container'>

        <div className='video-content row'>
          <div
            className='video-container col-12 col-md-6'
          >
            <iframe
              title='sales-video-landing'
              src='https://player.vimeo.com/video/684257199?h=8c988f3798&title=0&byline=0&portrait=0'
              className='video'
              frameBorder='0'
              allow='autoplay; fullscreen; picture-in-picture'
              allowFullScreen
              width='100%'

            // height='100%'s

            />
            <script src='https://player.vimeo.com/api/player.js' />
          </div>

          <div className='col-12 col-md-6 video-text'>
            <span className='video-text-title'>

              Assista o vídeo e descubra como utilizamos Lean e Metodologias Ágeis para transformar vidas e organizações inteiras.
            </span>

          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPageVideo
