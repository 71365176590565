import '../../input.css'
import Policy from './Policy'

const privacyPolicy = (
  <div className='leading-12'>
    <div>A presente Política de Privacidade “Política” é fornecida pela Lean it 101, nome empresarial LEANIT101 SERVIÇOS EM INFORMÁTICA, inscrita no CNPJ sob o número 22.724.260/0001-42, com sede na Rua Capri, nº 465, apto 302, Passa Vinte, Palhoça/SC - CEP 88132-229, contato eletrônico: contato@leanit101.com, com a finalidade de informar “Você”, na qualidade de “Aluno”, “Usuário” ou “Visitante”, a respeito do estabelecimento da presente Política de Privacidade e com a finalidade de tornar transparentes as suas atividades e demonstrar a privacidade das informações que são coletadas, além de como elas são tratadas, em que situações são compartilhadas e quando são excluídas, nos termos a seguir:</div>
    <br />
    <br />
    <div>DAS DEFINIÇÕES</div>
    <br />
    <div>No intuito de orientar a interpretação dos termos da presente Política, deve-se considerar as seguintes definições:</div>
    <br />
    <div>

      <ul>
        <li> - “Aluno”: trata-se do Usuário que está realizando o(s) Curso(s);</li>
        <li> - “Conteúdo”: todo material disponibilizado na Plataforma, tais como videoaulas, materiais de apoio, textos, apostilas, avaliações, atividades, simulados, modelos de maturidade (assessment) dentre outros;</li>
        <li> - “Plataforma”: Site, comunidade e qualquer ambiente virtual de Aprendizagem da Lean it 101 e também a plataforma de avaliação de maturidade organizacional (Assessment Platform);</li>
        <li> - “Política de Privacidade”: regras estatuídas pela Lean it 101 para proteção da privacidade do Usuário, bem como para trazer transparência acerca do tratamento dos dados e informações pessoais que são disponibilizados na Plataforma;</li>
        <li> - “<a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-protecao-aos-direitos-autorais'> Política de Proteção aos Direitos Autorais </a>”: regras estatuídas pela Lean it 101 para orientar “Você” sobre a proteção à propriedade intelectual conferida pela Lei de Direitos Autorais, pela Constituição Federal, e demais legislações aplicáveis;</li>
        <li> - “Usuário”: pessoa física ou jurídica por seu representante, que se cadastra na Plataforma e dela faz uso, a partir de um login e senha; e</li>
        <li> - “Você”: pessoa física ou jurídica que acessa, se cadastra, adquire cursos e/ou faz uso, de um modo geral, da Plataforma, na qualidade de “Aluno”, “Usuário” e/ou “Visitante”.</li>
      </ul>
    </div>
    <br />
    <br />
    <div>Valorizamos sua privacidade, seguimos a Lei Geral de Proteção de Dados (Lei n. 13.709/18), entendemos a relevância de cada dado pessoal para o usuário e queremos que você tenha tranquilidade para usar a plataforma, ciente de todo o fluxo envolvendo os dados coletados. Neste documento você encontrará tudo o que precisa saber sobre a forma como coletamos e tratamos os seus dados.</div>
    <br />
    <div>Esta Política integra os <a className='underline text-slate-400 hover:text-slate-300' href='/termos-de-uso'> Termos De Uso da Lean it 101  </a> .Você deve ler atenta e cuidadosamente as condições a seguir, pois elas contêm informações importantes sobre a privacidade e a proteção de seus dados pessoais.O tratamento dos dados pessoais é condição para utilizar a plataforma da Lean it 101. Não é possível oferecer as funcionalidades da plataforma sem ter acesso a esses dados pessoais.Esta Política e suas respectivas atualizações devem prevalecer sobre todas as políticas, propostas, contratos, entendimentos anteriores e acordos, orais ou escritos, eventualmente existentes entre as partes versando, direta ou indiretamente, sobre o tema da privacidade.</div>
    <br />
    <div>Alertamos que se você não concordar com o conteúdo desta política, não recomendamos a utilização desta plataforma.</div>
    <br />
    <br />
    <div>DOS DADOS COLETADOS</div>
    <br />
    <div> A Lean it 101 pode coletar todos os Dados inseridos por Você na Plataforma, tais como: </div>
    <br />

    <ul className='list-decimal list-inside pl-10'>
      <li> Nome completo; </li>
      <li> Idade; </li>
      <li> Escolaridade; </li>
      <li> Endereço de e-mail; </li>
      <li> Número de inscrição no Cadastro de Pessoas Físicas (CPF); </li>
      <li> Endereço; </li>
      <li> Número de telefone; </li>
      <li> Nome do titular do cartão de crédito usado para pagamentos; </li>
      <li> Número do cartão de crédito, data de vencimento e código de segurança; e </li>
      <li> Informações fornecidas por você nos contatos diretos com a Lean it 101, via e-mail, telefone, redes sociais, entre outros. </li>
    </ul>
    <br />
    <div>A Lean it 101 também coleta alguns Dados automaticamente quando Você acessa e usa a Plataforma, tais como:</div>
    <br />

    <ul className='list-decimal list-inside pl-10'>
      <li> Características do dispositivo de acesso; </li>
      <li> Dados do navegador utilizado para o acesso; </li>
      <li> Protocolo de Internet (IP, data, hora, minuto e segundo); </li>
      <li> Termos buscados pelo Usuário durante o uso da Plataforma; e </li>
      <li> Manifestações e comentários feitos pelos Usuários nos fóruns de dúvida, salas de bate-papo e/ou demais caixas de diálogo disponíveis na Plataforma. </li>
    </ul>

    <br />
    <div>Você, ciente das informações coletadas, manifesta o consentimento livre, expresso e informado com relação à coleta de tais informações, para fins do disposto no artigo 7º, inciso IX, da Lei 12.965/2014.</div>
    <br />
    <br />
    <div>DAS INFORMAÇÕES NÃO SOLICITADAS</div>
    <br />
    <div>Você possui a liberdade de enviar ideias, sugerir conteúdos e encaminhar outras informações não solicitadas para a Lean it 101, sendo estes denominados de “Informações Não Solicitadas”</div>
    <br />
    <div>Todas as Informações Não Solicitadas são consideradas e possuem caráter não confidencial e/ou sigiloso, podendo ser reproduzidas, divulgadas, utilizadas e distribuídas livremente pela Lean it 101, sem qualquer limitação ou atribuição.</div>
    <br />
    <br />
    <div>DA UTILIZAÇÃO, FINALIDADE E DO TRATAMENTO DAS INFORMAÇÕES</div>
    <br />
    <div>A Lean it 101 apenas utilizará as informações coletadas de acordo com as formas descritas na presente Política de Privacidade, na <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-protecao-aos-direitos-autorais'> Política de Proteção aos Direitos Autorais </a> e nos <a className='underline text-slate-400 hover:text-slate-300' href='/termos-de-uso'> Termos De Uso </a>, tendo em vista a sua autorização, e por serem todas consideradas como confidenciais;</div>
    <br />
    <div>Os Dados que coletamos são arquivados em nossa base de dados e tratados para:</div>
    <br />
    <ul className='list-decimal list-inside pl-10'>
      <li>Apresentar a Plataforma e seus conteúdos;</li>
      <li>Customizar, personalizar e adaptar o aprendizado;</li>
      <li>Comunicar-se e responder as comunicações;</li>
      <li>Verificar identidade;</li>
      <li>Fornecer informações, produtos ou serviços;</li>
      <li>Fornecer avisos sobre a conta, incluindo avisos de vencimento e renovação da assinatura;</li>
      <li>Realizar obrigações e exercer os direitos da Lean it 101 decorrentes de quaisquer contratos celebrados entre Você e a Lean it 101;</li>
      <li>Notificar sobre atualizações na Plataforma, na Política, nos <a className='underline text-slate-400 hover:text-slate-300' href='/termos-de-uso'> Termos De Uso </a> da Plataforma ou em quaisquer serviços oferecidos pela Lean it 101;</li>
      <li>Permitir que Você participe dos recursos interativos da Plataforma;</li>
      <li>Melhorar a qualidade dos Serviços, podendo realizar análises estatísticas, empíricas, quantitativas ou qualitativas das características e do comportamento coletivos dos “Alunos”, “Usuários” ou “Visitantes”;</li>
      <li>Administrar cursos, provas, testes, avaliações, exercícios, projetos e outras atividades, além de realizar avaliações por meio da Plataforma;</li>
      <li>Rastrear a participação, o progresso e a conclusão de atividades realizadas na Plataforma;</li>
      <li>Compartilhar informações de desempenho em cursos, provas, testes, avaliações, exercícios, projetos, outras atividades e outros serviços da Plataforma com os instrutores, professores, assistentes ou com inliíduos designados pela Lean it 101 para auxiliar na criação, modificação ou operação das atividades da Plataforma;</li>
      <li>Mapear cursos e demais atividades ou serviços ofertados na Plataforma que Você acessou;</li>
      <li>Permitir o envio de ofertas ou descontos em cursos, atividades ou outros serviços, que possam ser de seu interesse; e</li>
      <li>Permitir a comunicação entre Você e a Lean it 101, inclusive mediante o envio e recebimento de e-mails, contatos telefônicos e/ou envio de mensagens por redes sociais;</li>
      <li>Qualquer outro propósito, desde que com o seu consentimento prévio.</li>
    </ul>
    <br />
    <br />
    <div>Se Você não desejar receber e-mails promocionais deverá entrar em contato com a Lean it 101 por meio do Canal de Comunicação indicado nos itens 8.2 e 14.4 desta Política, solicitando o não recebimento dessas mensagens ou então Você pode realizar sua exclusão por meio dos links disponibilizados nos e-mails promocionais.</div>
    <br />
    <br />
    <div>DOS DADOS DE MENORES DE IDADE</div>
    <br />
    <div>Para os civilmente incapazes, a utilização da plataforma deve ser autorizada e realizada sob a supervisão dos pais ou responsáveis legais.</div>
    <br />
    <br />
    <div>DO COMPARTILHAMENTO DE INFORMAÇÕES</div>
    <br />
    <div>A Lean it 101 poderá compartilhar os Dados coletados por meio da Plataforma com empresas sediadas no Brasil ou em território internacional, sempre seguindo as medidas de segurança necessárias, nas seguintes situações:</div>
    <br />
    <ul className='list-decimal list-inside pl-10'>
      <li> Com empresas parceiras da Lean it 101, para fins publicitários, para oferecer produtos e anúncios personalizados a Você e para avaliar os Serviços e melhorar o seu oferecimento pela Lean it 101;</li>
      <li> Com autoridades governamentais para tomar medidas judiciais e administrativas para defender os interesses da Lean it 101 em qualquer tipo de conflito, incluindo ações judiciais, e/ou para cumprir determinações legais, judiciais e administrativas e também cumprir ofícios de autoridades;</li>
      <li> No caso de transações e alterações societárias envolvendo Lean it 101. A Lean it 101 poderá ainda transferir os seus Dados, de forma onerosa ou gratuita, a terceiro ou sucessor, caso se envolva em processo de falência, recuperação judicial ou extrajudicial, reestruturação societária, reorganização ou venda de ativos.Desde já, Você concorda que seus Dados poderão ser vendidos ou transferidos como parte dessa transação.Esta Política de Privacidade será aplicável aos seus Dados conforme sejam transferidos à(s) nova(s) entidade(s); e</li>
      <li> Sempre que necessário às atividades comerciais da Lean it 101 e/ou para o oferecimento dos Serviços, como, por exemplo, com operadoras de cartão de crédito, instituições financeiras, provedores de meios de pagamento, integradores de meios de pagamento, plataformas de controle do fluxo financeiro, softwares de suporte ao cliente, plataformas de e-commerce, plataformas de controle de pagamentos e sites das prefeituras municipais para emissão das notas fiscais dos Serviços.</li>
    </ul>
    <br />
    <div>Sempre que Você informar tais dados ou publicar conteúdos na plataforma Você concordará em dar publicidade a tais dados, de forma gratuita e por tempo indeterminado.</div>
    <br />
    <br />
    <div>DO ARMAZENAMENTO DAS INFORMAÇÕES</div>
    <br />
    <div>As informações fornecidas por Você nos sites, comunidades e plataformas, serão armazenadas pela Lean it 101, em servidores próprios ou por ela contratados, que podem estar localizados no Brasil ou em território estrangeiro.Por isso, Você consente que seus Dados sejam transferidos internacionalmente, podendo, assim, ser transferidos, armazenados e tratados no Brasil ou em território estrangeiro;</div>
    <br />
    <div>A Lean it 101 emprega todos os esforços razoáveis para garantir a segurança de seus sistemas na guarda de referidos dados; e</div>
    <br />
    <div>Esta Política representa o esforço da Lean it 101 no sentido de resguardar suas informações.No entanto, em razão da própria natureza da Internet, não é possível garantir que terceiros mal-intencionados não tenham sucesso em acessar indevidamente as informações obtidas.</div>
    <br />
    <br />
    <div>DA EXCLUSÃO DOS DADOS</div>
    <br />
    <div>A Lean it 101 poderá excluir seus Dados quando:</div>
    <br />
    <ul className='list-decimal list-inside pl-10'>
      <li> A finalidade para a qual o Dado foi coletado tenha sido alcançada ou quando os Dados deixarem de ser necessários para o alcance desta finalidade;</li>
      <li> Quando houver a cancelamento do consentimento dado por Você para tratamento dos Dados; ou</li>
      <li> Mediante determinação de autoridade.</li>
    </ul>
    <br />
    <div>Você pode solicitar a exclusão dos seus dados através dos e-mails contato @leanit101.com;</div>
    <br />
    <div>A Lean it 101 empreenderá os melhores esforços para atender a todos os pedidos de exclusão, no menor espaço de tempo possível.Tal exclusão, no entanto, removerá também o seu cadastro da Plataforma, impossibilitando novos acessos, inclusive a eventuais cursos e conteúdos adquiridos;</div>
    <br />
    <div>A Lean it 101 esclarece que respeitará o prazo de armazenamento mínimo de determinadas informações, conforme determinado pela legislação brasileira, ainda que Você solicite a exclusão de tais informações; e</div>
    <br />
    <div>A Lean it 101 reserva para si o direito de manter armazenados todos os Dados necessários ao cumprimento da legislação brasileira vigente, mesmo em caso de requisição de sua exclusão.</div>
    <br />
    <br />
    <div>DIREITOS DO USUÁRIO</div>
    <br />
    <div>Você poderá solicitar à Lean it 101:</div>
    <br />
    <div>A confirmação da existência de tratamento de seus Dados e o acesso aos seus Dados coletados por meio da Plataforma;</div>
    <br />
    <div>A correção de seus Dados, caso estes estejam incompletos, inexatos ou desatualizados;</div>
    <br />
    <div>A anonimização, o bloqueio ou a eliminação de Dados desnecessários, excessivos ou tratados em desconformidade com a legislação brasileira aplicável, conforme os recursos técnicos disponíveis à Lean it 101;</div>
    <br />
    <div>A portabilidade de seus Dados, para si ou para terceiro;</div>
    <br />
    <div>A eliminação dos Dados tratados com o seu consentimento, desde que não exista hipótese legal de permissão para conservá-los;</div>
    <br />
    <div>Obtenção de informações sobre entidades públicas ou privadas com as quais a Lean it 101 compartilhou seus Dados; e</div>
    <br />
    <div>Informações sobre a possibilidade de não fornecer o seu consentimento ao tratamento dos Dados, bem como de ser informado sobre suas consequências, em caso de negativa.</div>
    <br />
    <div>Nesse sentido, para que Você possa exercer seus direitos, basta que entre em contato conosco por meio do Canal de Comunicação indicado nos itens 8.2 e 14.4 desta Política.A Lean it 101 se esforçará para atendê-lo no menor tempo possível.</div>
    <br />
    <br />
    <div>LINKS DE TERCEIROS</div>
    <br />
    <div>Para a sua conveniência, a Lean it 101 pode divulgar links para outros sites administrados por empresas distintas (“Sites de Terceiros”).Os Sites de Terceiros operam independentemente da Plataforma e podem ter suas próprias políticas de privacidade.Você deve conhecer e analisar as políticas dos Sites de Terceiros caso acesse alguns destes Sites.Como os Sites de Terceiros não fazem parte da Plataforma, a Lean it 101 não é responsável por seus conteúdos ou suas práticas de privacidade.Logo, Você deve analisar a política de privacidade e os <a className='underline text-slate-400 hover:text-slate-300' href='/termos-de-uso'> Termos De Uso </a> que se aplicam aos Sites de Terceiros para se familiarizar com as respectivas práticas de coleta, uso, armazenamento, proteção e divulgação de informações pessoais.</div>
    <br />
    <br />
    <div>COOKIES</div>
    <br />
    <div>Para a realização de determinadas atividades, a Lean it 101 poderá utilizar cookies ou tecnologias semelhantes.Cookies são pequenos arquivos usados para reconhecer, acompanhar e armazenar a sua navegação como usuário na Internet;</div>
    <br />
    <div>A Lean it 101 poderá usar os cookies ou tecnologias semelhantes para todos os fins previstos nesta Política, principalmente para a personalização de conteúdo, anúncios e preferências de configuração da conta criada por Você para utilizar a Plataforma; e</div>
    <br />
    <div>Você pode alterar as configurações do seu navegador para bloquear cookies ou gerenciar o seu uso, bloqueando cookies de todos os sites ou de sites específicos.É possível que o funcionamento da Plataforma e parte ou a totalidade dos Serviços ofertados pela Lean it 101 sejam prejudicados por tal bloqueio.Consulte as instruções do seu navegador para saber mais como configurar o uso de cookies.</div>
    <br />
    <br />
    <div>DA ACEITAÇÃO POR VOCÊ</div>
    <br />
    <div>A aceitação desta Política de Privacidade pelo Usuário se dará no ato do seu clique no botão “Eu li e concordo”, ao se cadastrar na Plataforma e, em relação ao Usuário ou “Visitante, quando faz a navegação e utilização da Plataforma;</div>
    <br />
    <div>Caso Você não concorde com a presente Política de Privacidade, não poderá realizar a aquisição de nenhum produto, devendo se abster de acessar e utilizar a plataforma de ensino;</div>
    <br />
    <div>No acesso, navegação, cadastro e/ou utilização da Plataforma, aplicam-se as disposições constantes nos <a className='underline text-slate-400 hover:text-slate-300' href='/termos-de-uso'> Termos De Uso </a> e <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-protecao-aos-direitos-autorais'> Política de Proteção aos Direitos Autorais </a>, conjuntamente com esta Política de Privacidade;</div>
    <br />
    <div>Ao acessar e se cadastrar na Plataforma, Você declara ter no mínimo 18 (dezoito) anos, bem como atesta ser civilmente capaz para compreender, aceitar e cumprir estes <a className='underline text-slate-400 hover:text-slate-300' href='/termos-de-uso'> Termos De Uso </a>, a Política de Privacidade, e a <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-protecao-aos-direitos-autorais'> Política de Proteção aos Direitos Autorais </a>.Caso não tenha no mínimo 18 (dezoito) anos, Você deverá seguir o item 5 desta Política; e</div>
    <br />
    <div>Você concorda e permite o acesso às suas informações a partir de seu cadastro e ou acesso ao conteúdo, sites, comunidades e plataformas da Lean it 101, manifestando consentimento livre, expresso e informado, nos termos do artigo 43 do Código de Defesa do Consumidor e artigo 7º, inciso IX, da Lei 12.965/2014.</div>
    <br />
    <br />
    <div>DA ATUALIZAÇÃO DESTA POLÍTICA</div>
    <br />
    <div>A Lean it 101 se reserva o direito de alterar essa Política de Privacidade sempre que necessário, com o fito de fornecer maior segurança e praticidade a Você, assim como para cumprir a legislação aplicável, o que poderá se dar sem prévia notificação, salvo em caso de expressa vedação legal.</div>
    <br />
    <div>É essencial que Você leia a Política de Privacidade, os <a className='underline text-slate-400 hover:text-slate-300' href='/termos-de-uso'> Termos De Uso </a> e a <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-protecao-aos-direitos-autorais'> Política de Proteção aos Direitos Autorais </a> da Lean it 101, destarte, acompanhando também cada atualização e modificação dos mesmos.</div>
    <br />
    <div>Cada nova atualização, poderá ser averiguada pela data constante ao final dos respectivos documentos anteriormente citados.</div>
    <br />
    <br />
    <div>DAS DISPOSIÇÕES FINAIS</div>
    <br />
    <div>O uso indevido do Conteúdo da Lean it 101 será regido e interpretado pelas leis da República Federativa do Brasil, independentemente dos conflitos dessas leis com leis de outros estados ou países, sendo competente o Foro da Comarca de Palhoça/SC, no Brasil, para dirimir qualquer dúvida decorrente deste instrumento.Você consente, expressamente, com a competência desse juízo, e renúncia, neste ato, à competência de qualquer outro foro, por mais privilegiado que seja ou venha a ser.</div>
    <br />
    <div>Eventual falha da Lean it 101 em exigir quaisquer direitos não constituirá renúncia, podendo exercer regularmente o seu direito, dentro dos prazos legais.</div>
    <br />
    <div>Ao adquirir qualquer produto da Lean it 101, Você concorda em se vincular a todas as cláusulas da presente Política de Privacidade, bem como dos <a className='underline text-slate-400 hover:text-slate-300' href='/termos-de-uso'> Termos De Uso </a> e da <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-protecao-aos-direitos-autorais'> Política de Proteção aos Direitos Autorais </a> da Lean it 101.</div>
    <br />
    <div>Qualquer problema relacionado à privacidade e proteção de dados deve ser reportado a nossa equipe que estará à disposição para atender e sanar todas as suas dúvidas, através do seguinte e-mail: contato @leanit101.com.</div>
    <br />
    <div>Fique à vontade para entrar em contato conosco em caso de dúvidas sobre quaisquer das práticas relativas a informações nos Serviços e/ou sobre os <a className='underline text-slate-400 hover:text-slate-300' href='/termos-de-uso'> Termos De Uso </a>, a Política de Privacidade, e a <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-protecao-aos-direitos-autorais'> Política de Proteção aos Direitos Autorais </a>.</div>
    <br />
    <div>Bem-vindo à Lean it 101</div>
    <br />
    <div>Última atualização: 15.09.2022</div>
  </div>
)

const PrivacyPolicy = () => {
  return (
    <div className=''>
      <Policy name='Politica de privacidade' text={privacyPolicy} />
    </div>
  )
}

export default PrivacyPolicy
