import '../../input.css'

import { launchEnteredWhatsappGroupEvent } from './FaceBookEventTracking'
const EbookPage = () => {
  return (
    <div
      id='CoverPage'
      className='flex md:h-screen h-full text-white flex-col bg-gradient-radial to-steelGray via-leanit101Blue from-leanit101BlueLight4 font-Barlow'
    >
      <div className='bg-vulcan h-16 w-full flex justify-center items-center'>
        <img src='images/logo-leanit101-slogan.png' alt='Logo' className='h-2/3' />
      </div>

      <div className='flex md:flex-row flex-col  justify-between items-center h-full py-4 '>

        <div className='flex flex-col 2xl:flex-row justify-around md:px-24 px-3 w-full items-center'>

          <div className=' mt-4 flex items-center flex-col'>
            <span className='md:text-5xl text-3xl text-center font-black md:max-w-[700px] drop-shadow-2xl'>
              SUA INSCRIÇÃO ESTÁ QUASE CONCLUIDA
            </span>
            <div className='md:w-[500px] self-center flex p-4'>
              <img src='images/launch/1.png' alt='' />
            </div>
          </div>
          <div className='flex flex-col text-left gap-3 md:w-[600px] h-fit bg-vulcan drop-shadow-2xl border-1 border-solid border-pyramid-19 rounded-xl md:p-12 p-7'>
            <span className='text-3xl font-black'>
              MUITO OBRIGADO!
            </span>
            <span className='text-lg py-5'>
              Nos próximos minutos você receberá um email com informações relevantes para concluir sua inscrição. Não esqueça de verificar a caixa de spam e a de promoção caso não receba.
              <br />
              <br />

              <span className='font-black'>
                Muito importante
              </span>
              <br />
              <br />
              As aulas exclusivas somente ocorrerão dentro do grupo VIP do Whatsapp.
              Clique no botão a baixo para entrar no grupo.

              <br />
              <br />
              As aulas do dia 1 a 5 de Fereveiro não serão gravadas e não estarão disponíveis posteriormente.
              <br />
            </span>

            <button
              className='text-white bg-cyan-600 hover:bg-cyan-500 hover:cursor-pointer font-black rounded-full w-fit p-5 text-xl px-12 self-center'
              type='button'
              onClick={() => {
                launchEnteredWhatsappGroupEvent()
                window.location.href = 'https://chat.whatsapp.com/IJXIMlgQQi91FcWHG3zlop'
              }}
            >
              ENTRAR NO GRUPO VIP
            </button>

          </div>
        </div>
      </div>
    </div>
  )
}

export default EbookPage
