import LandingPageVideo from './LandingPageVideo/LandingPageVideo'
import LandingPageCover from './LandingPageCover/LandingPageCover'
import LandingPageWaste from './LandingPageWaste/LandingPageWaste'
import LandingPagePersonas from './LandingPagePersonas/LandingPagePersonas'
import LandingPageContent from './LandingPageContent/LandingPageContent'
import LandingPageAcess from './LandingPageAcess/LandingPageAcess'
import LandingPageWarranty from './LandingPageWarranty/LandingPageWarranty'
import LandingPageTeacher from './LandingPageTeacher/LandingPageTeacher'
import LandingPageFaq from './LandingPageFaq/LandingPageFaq'
import LandingPageLifes from './LandingPageLifes/LandingPageLifes'
import LandingPageCta from './LandingPageCta/LandingPageCta'
import './LandingPage.scss'
import { Helmet } from 'react-helmet'
import React from 'react'

const LandingPage = () => {
  return (
    <div className='LandingPage'>
      <Helmet>
        <title>Lean Agile Foundation</title>
        <meta name='title' content='Lean Agile Foundation' />
        <meta
          name='description'
          content='Aprenda toda a essência do Lean e do Agile para transformar a sua organização e entregar mais valor para os seus clientes em menos tempo'
        />
        <link rel='canonical' href='http://leanit101/lean-agile-foundation' />
      </Helmet>
      <LandingPageCover />
      <LandingPageVideo />
      <LandingPageWaste />
      <LandingPagePersonas />
      <LandingPageContent />
      <LandingPageAcess />
      <LandingPageWarranty />
      <LandingPageLifes />
      <LandingPageTeacher />
      <LandingPageFaq />
      <LandingPageCta />
    </div>
  )
}

export default LandingPage
