import './LandingPageFaq.scss'

const LandingPageFaq = () => {
  return (
    <div className='LandingPageFaq'>

      <div
        className='faq-background' style={{
          backgroundImage: "url('/images/landing-lean-agile-foundation/noNoiseFaqLp.png')",
          backgroundRepeat: 'no-repeat',
          // minHeight: 'max(41.6vw, 700px)',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          opacity: '1'
        }}
      >
        <div className='container text-base-start text-center'>
          <div className='row'>
            <span className='faq-title white'>
              Perguntas Frequentes
            </span>
          </div>
          <div className='row h-100'>

            <div className='col-12 col-md-6'>
              <div className='text-base-start text-center'>

                <div>
                  <span
                    className=''
                    data-bs-toggle='collapse'
                    href='#faq-1'
                    role='button'
                    aria-expanded='false'
                    aria-controls='collapseExample'
                  >
                    <h1 className='faq-question'>
                      <span className='orange'>
                        Quais as formas de pagamento?
                      </span>

                    </h1>
                  </span>

                  <div className='collapse h5' id='faq-1'>
                    <div className='white  faq-answer'>
                      O pagamento pode ser feito à vista, boleto bancário ou via cartão de crédito em até 12x.
                    </div>
                  </div>
                </div>

                <div>
                  <span
                    className=''
                    data-bs-toggle='collapse'
                    href='#faq-2'
                    role='button'
                    aria-expanded='false'
                    aria-controls='collapseExample'
                  >
                    <h1 className='faq-question'>
                      <span className='orange'>
                        Como é liberado o acesso ao curso?
                      </span>

                    </h1>
                  </span>

                  <div className='collapse h5' id='faq-2'>
                    <div className='white faq-answer'>
                      Após a compensação do pagamento, é liberado acesso via e-mail informado no momento da compra. O curso está hospedado na plataforma Lean it 101 Academy e você pode estudar tanto no computador, como no tablet ou celular.
                    </div>
                  </div>
                </div>

                <div>
                  <span
                    className=''
                    data-bs-toggle='collapse'
                    href='#faq-6'
                    role='button'
                    aria-expanded='false'
                    aria-controls='collapseExample'
                  >
                    <h1 className='faq-question'>
                      <span className='orange'>
                        Quanto tempo de acesso?
                      </span>

                    </h1>
                  </span>

                  <div className='collapse h5' id='faq-6'>
                    <div className='white faq-answer'>
                      Você terá direito a todas as atualizações e acesso ao treinamento durante um ano.
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div className='col-12 col-md-6'>
              <div className='text-base-start text-center'>

                <div>
                  <span
                    className=''
                    data-bs-toggle='collapse'
                    href='#faq-3'
                    role='button'
                    aria-expanded='false'
                    aria-controls='collapseExample'
                  >
                    <h1 className='faq-question'>

                      <span className='orange'>
                        O curso possui algum pré-requisito?
                      </span>

                    </h1>
                  </span>

                  <div className='collapse h5' id='faq-3'>
                    <div className='white faq-answer'>
                      Não há nenhum pré-requisito para adquirir o curso Lean and Agile Foundation.
                    </div>
                  </div>
                </div>

                <div>
                  <span
                    className=''
                    data-bs-toggle='collapse'
                    href='#faq-4'
                    role='button'
                    aria-expanded='false'
                    aria-controls='collapseExample'
                  >
                    <h1 className='faq-question'>

                      <span className='orange'>
                        Quanto tempo de garantia?
                      </span>

                    </h1>
                  </span>

                  <div className='collapse h5' id='faq-4'>
                    <div className='white faq-answer'>
                      Caso você sinta que não é o momento para sua jornada de transformação, você possui o direito de 100% do reembolso em até 7 dias após a confirmação da sua compra.
                    </div>
                  </div>
                </div>

                <div>
                  <span
                    className=''
                    data-bs-toggle='collapse'
                    href='#faq-5'
                    role='button'
                    aria-expanded='false'
                    aria-controls='collapseExample'
                  >
                    <h1 className='faq-question'>

                      <span className='orange'>
                        É possível dividir o curso com algum amigo?
                      </span>

                    </h1>
                  </span>

                  <div className='collapse h5' id='faq-5'>
                    <div className='white faq-answer'>
                      Não é possível dividir o curso, o uso é pessoal e intransferível.
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default LandingPageFaq
