import '../../input.css'

const EbookPage = () => {
  return (
    <div
      id='CoverPage'
      className='flex md:h-screen h-full text-white flex-col bg-gradient-radial to-steelGray via-leanit101Blue from-leanit101BlueLight4 font-Barlow'
    >
      <div className='bg-vulcan h-16 w-full flex justify-center items-center'>
        <img src='images/logo-leanit101-slogan.png' alt='Logo' className='h-2/3' />
      </div>

      <div className='flex md:flex-row flex-col  justify-between items-center h-full py-4 '>

        <div className='flex flex-col 2xl:flex-row justify-around md:px-24 px-3 w-full items-center'>

          <div className=' mt-4 flex items-center flex-col'>
            <span className='md:text-5xl text-3xl text-start font-black md:max-w-[700px] drop-shadow-2xl'>
              ATITUDE FAZ UMA DIFERANÇA
            </span>
            <span className='md:text-8xl text-3xl text-start font-black md:max-w-[700px] drop-shadow-2xl'>
              GIGANTESCA
            </span>
            <div className='md:w-[700px] self-center flex p-4'>
              <img src='images/ebookmockup.png' alt='' />
            </div>
          </div>

          <div className='flex flex-col text-left gap-3 md:w-[600px] h-fit bg-vulcan drop-shadow-2xl border-1 border-solid border-pyramid-19 rounded-xl md:p-12 p-7'>
            <span className='text-3xl font-black text-pyramid-1'>
              MUITO OBRIGADO!
            </span>
            <span className='text-lg font-black pt-5'>
              Clique no botão abaixo para baixar o seu ebook!
            </span>
            <span className='text-lg pb-10'>

              Mais do que ler esse ebook, desejamos que você tenha a  <span className='text-pyramid-1 font-medium'>atitude necessária para por em prática</span> as coisas que você irá aprender aqui.
              <br />
              <br />
              Conte sempre conosco na sua jornada de transformação!

            </span>

            <a
              href='https://leanit101.com/images/byBndWlhIGRlZmluaXRpdm8gZGEgdHJhbnNmb3JtYefjbyBsZWFuIGFnaWxl/O%20GUIA%20DEFINITIVO%20DA%20TRANSFORMAÇÃO%20LEAN%20AGILE.pdf' download='O%20GUIA%20DEFINITIVO%20DA%20TRANSFORMAÇÃO%20LEAN%20AGILE.pdf'
              className='text-white bg-cyan-600 hover:bg-cyan-500 hover:cursor-pointer font-black rounded-full w-fit p-5 text-xl px-12 self-center'
            >
              BAIXAR EBOOK
            </a>

          </div>
        </div>
      </div>
    </div>
  )
}

export default EbookPage
