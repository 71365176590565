import { useTranslation } from 'react-i18next'
import '../../input.css'

const WarrantySection = () => {
  const [t] = useTranslation()
  return (
    <div
      id='WarrantySection'
      className='bg-vulcan w-full h-full flex flex-col md:flex-row px-[1rem] md:px-48 md:p-24 p-6  items-center md:justify-center'
    >
      <div className='md:w-3/5 p-12 w-full h-full'>
        <img src='images/WarrantySeal.svg' alt='' className='drop-shadow-xl' />
      </div>
      <div className='flex flex-col md:justify-center md:items-start items-center font-bold w-full h-full text-center md:p-3 py-3'>
        <h1 className='text-4xl font-black md:text-5xl pb-[1rem] md:pb-10 md:text-left mb-5 '>
          {t('warranty.title')}
        </h1>
        <h2 className='text-lg md:text-2xl font-medium md:text-left'>
          {t('warranty.preocupe')}
          <span className='font-black px-2'>
            {t('warranty.risco')}
          </span>
          <br />
          <br />
          {t('warranty.garantido')}
          <br />
          <br />
          <span className='italic text-base font-light'>
            {t('warranty.comprar')}
          </span>
        </h2>
      </div>
    </div>
  )
}

export default WarrantySection
