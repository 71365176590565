
import '../../input.css'

import TaskAltIcon from '@mui/icons-material/TaskAlt'
import CtaButton from './CtaButton'
import { useTranslation } from 'react-i18next'

const FeatureIcon = ({ text }) => {
  return (
    <div className='flex flex-col items-center justify-center my-3 m-2 w-32 '>
      <TaskAltIcon fontSize='' className='h-12 w-12 md:text-4xl text-2xl m-2' />
      <span className='text-lg md:text-xl leading-5 text-white'>
        {text}
      </span>
    </div>
  )
}

const CoverPage = () => {
  const [t] = useTranslation()

  return (
    <div
      id='CoverPage'
      className='flex justify-between flex-col bg-gradient-to-t to-steelGray via-leanit101Blue from-leanit101BlueLight4 pt-18 md:pt-40 z-40'
    >
      <div className='flex flex-col 2xl:flex-row w-full'>
        <div className='h-full w-full flex flex-initial flex-col font-bold  sm:text-left items-center sm:justify-start sm:items-start sm:p-3 md:pl-28'>

          <span className='sm:text-5xl text-2xl font-bold'>

            {t('cover.transform')}
            <br />
            {t('cover.development')}
            <br />
            {t('cover.ever')}
          </span>

          <span className='text-sm sm:text-2xl mt-4 italic font-medium '>
            {t('cover.learn')}
            <br />
            {t('cover.better')}
          </span>
          <div className='my-20 sm:flex-col sm:items-start hidden sm:flex'>
            <CtaButton />
          </div>
        </div>

        <div className='flex flex-auto justify-end md:px-28 md:p-0 2xl:p-10 p-2 py-5 w-full'>
          <iframe
            style={{
              border: '1px solid rgba(11, 166, 218, 0.6)'
            }}
            className='rounded-2xl drop-shadow-xl aspect-video'
            width='100%'
            src='https://www.youtube.com/embed/0Hx-D75d21g?controls=0'
            title='O que é lean? O que você precisa saber sobre Lean'
          />
        </div>

        <div className='flex flex-col md:items-start sm:hidden'>
          <CtaButton />
          <span className='flex justify-center font-semibold md:mt-5 mt-3 leading-5 text-lg p-2'>

            {t('cover.first_step')}

            <br />

            {t('cover.results')}

          </span>
        </div>

      </div>
      <span className=' justify-center font-semibold self-end pr-28  mt-3 leading-5 text-lg hidden sm:flex'>

        {t('cover.first_step')}

        {t('cover.results')}

      </span>
      <div className='flex flex-wrap flex-row text-sky-400 justify-center items-start lg:justify-between  font-medium md:p-28 md:pt-12 p-2 bg-gradient-to-b from-transparent to-ebony'>
        <FeatureIcon text={t('cover.feature_1')} />
        <FeatureIcon text={t('cover.feature_2')} />
        <FeatureIcon text={t('cover.feature_3')} />
        <FeatureIcon text={t('cover.feature_4')} />
        <FeatureIcon text={t('cover.feature_5')} />
      </div>
    </div>
  )
}

export default CoverPage
