import { useTranslation } from 'react-i18next'
import '../../../input.css'
import Module from './Module'

const ModulesSection = () => {
  const [t] = useTranslation()
  const modules = [
    {
      title: t('modules.module_1.title'),
      description: t('modules.module_1.description'),
      benefits: [
        t('modules.module_1.benefits.benefit_1'),
        t('modules.module_1.benefits.benefit_2'),
        t('modules.module_1.benefits.benefit_3'),
        t('modules.module_1.benefits.benefit_4'),
        t('modules.module_1.benefits.benefit_5'),
        t('modules.module_1.benefits.benefit_6'),
        t('modules.module_1.benefits.benefit_7'),
        t('modules.module_1.benefits.benefit_8')
      ],
      image: 'images/MODULES/MODULE1.png',
      link: 'https://leanit101.com',
      duration: '4hr10m',
      numberOfClasses: '9 ' + t('modules.aulas'),
      classes: [
        {
          title: t('modules.module_1.classes.class_1.title'),
          time: '44:10',
          items: [
            t('modules.module_1.classes.class_1.item_1')
          ]
        },
        {
          title: t('modules.module_1.classes.class_2.title'),
          time: '14:26',
          items: [
            t('modules.module_1.classes.class_2.item_1'),
            t('modules.module_1.classes.class_2.item_2'),
            t('modules.module_1.classes.class_2.item_3'),
            t('modules.module_1.classes.class_2.item_4'),
            t('modules.module_1.classes.class_2.item_5'),
            t('modules.module_1.classes.class_2.item_6'),
            t('modules.module_1.classes.class_2.item_7')
          ]
        },
        {
          title: t('modules.module_1.classes.class_3.title'),
          time: '19:04',
          items: [
            t('modules.module_1.classes.class_3.item_1'),
            t('modules.module_1.classes.class_3.item_2'),
            t('modules.module_1.classes.class_3.item_3'),
            t('modules.module_1.classes.class_3.item_4'),
            t('modules.module_1.classes.class_3.item_5'),
            t('modules.module_1.classes.class_3.item_6')
          ]
        },
        {
          title: t('modules.module_1.classes.class_4.title'),
          time: '35:52',
          items: [
            'Muda',
            'Muri',
            'Mura'
          ]
        },
        {
          title: t('modules.module_1.classes.class_5.title'),
          time: '20:11',
          items: [
            t('modules.module_1.classes.class_5.item_1'),
            t('modules.module_1.classes.class_5.item_2'),
            t('modules.module_1.classes.class_5.item_3'),
            t('modules.module_1.classes.class_5.item_4'),
            t('modules.module_1.classes.class_5.item_5'),
            t('modules.module_1.classes.class_5.item_6')
          ]
        },
        {
          title: t('modules.module_1.classes.class_6.title'),
          time: '26:37',
          items: [
            t('modules.module_1.classes.class_6.item_1'),
            t('modules.module_1.classes.class_6.item_2'),
            t('modules.module_1.classes.class_6.item_3'),
            t('modules.module_1.classes.class_6.item_4'),
            t('modules.module_1.classes.class_6.item_5'),
            t('modules.module_1.classes.class_6.item_6'),
            t('modules.module_1.classes.class_6.item_7'),
            t('modules.module_1.classes.class_6.item_8')
          ]
        },
        {
          title: t('modules.module_1.classes.class_7.title'),
          time: '20:10',
          items: [
            t('modules.module_1.classes.class_7.item_1'),
            t('modules.module_1.classes.class_7.item_2'),
            t('modules.module_1.classes.class_7.item_3'),
            t('modules.module_1.classes.class_7.item_4'),
            t('modules.module_1.classes.class_7.item_5'),
            t('modules.module_1.classes.class_7.item_6'),
            t('modules.module_1.classes.class_7.item_7')
          ]
        },
        {
          title: t('modules.module_1.classes.class_8.title'),
          time: '28:23',
          items: [
            t('modules.module_1.classes.class_8.item_1'),
            t('modules.module_1.classes.class_8.item_2'),
            t('modules.module_1.classes.class_8.item_3'),
            t('modules.module_1.classes.class_8.item_4'),
            t('modules.module_1.classes.class_8.item_5'),
            t('modules.module_1.classes.class_8.item_6'),
            t('modules.module_1.classes.class_8.item_7')
          ]
        },
        {
          title: t('modules.module_1.classes.class_9.title'),
          time: '41:47',
          items: [
            t('modules.module_1.classes.class_9.item_1'),
            t('modules.module_1.classes.class_9.item_2'),
            t('modules.module_1.classes.class_9.item_3'),
            t('modules.module_1.classes.class_9.item_4')
          ]
        }
      ]
    },
    {
      title: 'Construção de times de alta performance',
      description: 'Este treinamento ensina como dar o pontapé inicial e construir equipes altamente eficazes. Abrange toda a jornada de Tuckman, contemplando formação, confrontamento, normatização e performance de uma equipe, além de aspectos chave relacionados a liderança e estrutura de times de alto desempenho.',
      benefits: [
        'Criação de times ágeis com eficácia e em menor tempo',
        'Onboarding mais efetivo para novos membros',
        'Desenvolvimento individual potencializado',
        'Transformação para equipes já existentes',
        'Melhor interação, colaboração e comunicação',
        'Resolução de conflitos facilitada'
      ],
      image: 'images/MODULES/MODULE2.png',
      link: 'https://leanit101.com',
      duration: '1hr19m',
      numberOfClasses: '3 ' + t('modules.aulas'),
      classes: [
        {
          title: 'COMPREENSÃO DO PROPÓSITO',
          time: '23:00',
          items: [
            'Por que o propósito impulsiona a mudança',
            'Comece pelo porquê',
            'Círculo de Ouro',
            'Os 5 passos para conduzir a mudança'
          ]
        },
        {
          title: 'EMPODERANDO PESSOAS',
          time: '36:00',
          items: [
            'Autonomia, domínio e propósito',
            'Liderança efetiva',
            'Equipes eficazes',
            'Compartilhando princípios e valores'
          ]
        },
        {
          title: 'FORMAÇÃO DE EQUIPE',
          time: '20:00',
          items: [
            'Formação, confrontamento, normatização e performance',
            'Filiação de membros',
            'Configuração de contexto',
            'Sistema de gestão',
            'Rotinas',
            'Acordos de trabalho',
            'Gestão visual'
          ]
        }
      ]
    },
    {
      title: 'Gestão da estratégia de negócios com Hoshin Kanri', // 333333
      description: 'A estratégia de negócios é muito importante para ficar apenas com os executivos. Neste treinamento, você aprenderá como o Hoshin Kanri é aplicado para criar senso de propriedade e comprometimento em todos os colaboradores, promovendo o alinhamento da organização em relação à estratégia do negócio. Você aprenderá como implementar ferramentas eficazes para a condução do planejamento estratégico, bem como para sua execução e obtenção dos seus objetivos de negócio.',
      benefits: [
        'Colaboradores atuando diariamente com propriedade e compromisso com a estratégia de negócios',
        'Evitar incêndios com tópicos “estratégicos” não planejados e pressões trimestrais sobre resultados',
        'Foco nas atividades de curto prazo que realmente contribuam com a obtenção dos objetivos estratégicos',
        'Processo contínuo de verificação para garantir que o que é feito a cada dia, realmente reflita as intenções, as metas e a visão que a empresa concordou em perseguir',
        'Cada colaborador com clareza de seu papel, seus objetivos e dos objetivos da organização',
        'Liderança evidente em todos os níveis',
        'Alinhamento claro de métricas de performance'
      ],
      image: 'images/MODULES/MODULE3.png',
      link: 'https://leanit101.com',
      duration: '1hr',
      numberOfClasses: '3 ' + t('modules.aulas'),
      classes: [
        {
          title: 'POR QUE HOSHIN KANRI',
          time: '21:13',
          items: [
            'Hoshin Kanri Genesis',
            'Definição do Hoshin Kanri',
            'Por que usar Hoshin Kanri',
            'Benefícios do Hoshin Kanri'
          ]
        },
        {
          title: 'PROCESSO DE PLANEJAMENTO HOSHIN KANRI',
          time: '29:21',
          items: [
            'Modelo Hoshin Kanri',
            'Etapas do processo',
            'Posicionamento de mercado',
            'Propósito, Visão Organizacional, Missão',
            'Objetivos estratégicos disruptivos',
            'Decompondo as metas de negócios',
            'Desdobramento do plano',
            'Institucionalizando objetivos estratégicos',
            'Filosofia Operacional',
            'Implementação e revisão de progresso',
            'Princípios chave',
            'PDCA'
          ]
        },
        {
          title: 'FERRAMENTAS DO HOSHIN KANRI',
          time: '8:21',
          items: [
            'Hoshin Kanri na Toyota',
            'Processo prático de resolução de problemas',
            'Matriz de Planejamento Hoshin Kanri'
          ]
        }
      ]
    },
    {
      title: 'Gestão de backlogs ágeis',
      description: 'Aprenda a usar estórias do usuário para gerir com eficiência seus objetivos de negócio e gerenciar as atividades estratégicas, táticas e operacionais de maneira ágil. Se você estiver usando Scrum, Kanban, desenvolvendo um produto ou gerenciando uma organização de serviços, este treinamento o ajudará a preparar seus backlogs e alcançar maior produtividade em todas as fases.',
      benefits: [
        'Aumento da produtividade e da assertividade das entregas',
        'Máxima entrega de valor',
        'Decomposição adequada do portfólio de projetos, serviços e produtos',
        'Melhor colaboração e comunicação entre as áreas',
        'Cliente presente e design centrado no usuário',
        'Entendimento compartilhado sobre problema e solução',
        'Redução de riscos e melhor gerenciabilidade'
      ],
      image: 'images/MODULES/MODULE4.png',
      link: 'https://leanit101.com',
      duration: '40m',
      numberOfClasses: '2 ' + t('modules.aulas'),
      classes: [
        {
          title: 'CONTE BOAS ESTÓRIAS',
          time: '21:38',
          items: [
            'Habilitando pequenos lotes',
            'Diminuindo o custo de transação',
            'Encurtando ciclos de feedback',
            'Foco no Incremento Mínimo de Balor',
            'EstóriaS do usuário',
            'Épico',
            'Tema',
            'Critérios de aceitação',
            'BDD - Desenvolvimento Orientado ao Comportamento'
          ]
        },
        {
          title: 'GERENCIE SEU BACKLOG DE PRODUTOS',
          time: '17:56',
          items: [
            'Refinamento do backlog',
            'Definição de pronto',
            'Definição de feito',
            'Story Mapping',
            'Backlogs estratégico, tático e operacional',
            'Sequenciamento da produção'
          ]
        }

      ]
    },
    {
      title: 'Otimizando o fluxo de desenvolvimento de produtos',
      description: 'Desenvolver produtos que os clientes amam é uma tarefa muito complexa. Este treinamento irá dissecar as táticas utilizadas pela Toyota não apenas para desenvolver produtos assertivos que encantem os clientes, mas principalmente para otimizar o fluxo de produção. Abrange todos os aspectos necessários que você precisa implementar, antes que a primeira linha de código seja escrita, incluindo aspectos de negócios e tecnologia.',
      benefits: [
        'Maximização do valor entregue aos clientes',
        'Maior eficácia na captura e validação de problemas e oportunidades',
        'Design de solução efetivo com participação de todas as áreas',
        'Redução de impedimentos e aumento da produtividade do time',
        'Efetividade em todo o fluxo de desenvolvimento de produtos'
      ],
      image: 'images/MODULES/MODULE5.png',
      link: 'https://leanit101.com',
      duration: '1hr10m',
      numberOfClasses: '3 ' + t('modules.aulas'),
      classes: [
        {
          title: 'DANDO FORMA A DEMANDA',
          time: '23:25',
          items: [
            'Métricas Lean',
            'Lead Time',
            'Reaction Time',
            'Cycle Time',
            'Added Value Time',
            'Non-Added Value Time',
            'Touch Time',
            'Queue Time',
            'Waiting Time',
            'Throughput',
            'Takt Time',
            'Mapeamento do fluxo de valor',
            'Explorando a variabilidade',
            'Reduzindo o número de peças móveis',
            'Ajustando o tamanho do lote',
            'Padronização do fluxo'
          ]
        },
        {
          title: 'INCEPTION DE NEGÓCIOS',
          time: '36:56',
          items: [
            'Disfunções comuns no desenvolvimento de produtos',
            'Abordagem Kentou da Toyota',
            'Inception Deck',
            'Desenvolvendo o contexto do negócio',
            'Custo, Prazo e escopo',
            'Mercado, clientes e competidores',
            'Análise SWOT',
            'Captura de problemas e oportunidades',
            'Validando hipóteses de problema, solução e de modelo de negócios',
            'Alinhando expectativas de negócio',
            'Entendendo personas',
            'Design de produto com engenharia simultânea'
          ]
        },
        {
          title: 'INCEPTION DE TECNOLOGIA',
          time: '10:02',
          items: [
            'Design de arquitetura de ponta a ponta',
            'Infraestrutura de testes automatizados',
            'Migração automatizada de banco de dados',
            'Build e deployment',
            'Verificação da qualidade',
            'Controles de segurança',
            'Virtualização e versionamento de ambientes',
            'Pipeline de entrega contínua'
          ]
        }
      ]
    },
    {
      title: 'Gestão econômica do fluxo de produção',
      description: 'Maximizar a entrega de valor é imperativo em qualquer organização moderna. No entanto, apenas eliminar o desperdícios não é o suficiente. Precisamos aprender a maximizar a entrega de valor na linha de produção, aplicando  métodos adequados de sequenciamento. Neste treinamento você aprenderá a priorizar melhor e a obter ganhos econômicos por meio de um melhor gerenciamento do fluxo.',
      benefits: [
        'Redução de custos de desenvolvimento',
        'Otimização econômica do processo produtivo',
        'Maior valor gerado para o cliente',
        'Compreensão clara dos custos inerentes ao fluxo',
        'Compreensão de como usar gestão de fluxo unificado'
      ],
      image: 'images/MODULES/MODULE6.png',
      link: 'https://leanit101.com',
      duration: '39m',
      numberOfClasses: '2 ' + t('modules.aulas'),
      classes: [
        {
          title: 'ENTENDO OS CUSTOS DE FLUXO',
          time: '22:00',
          items: [
            'Custo de estoque',
            'Custo de transação',
            'Custo de coordenação',
            'Custo do atraso',
            'Custo de filas',
            'Custo do excesso de capacidade'
          ]
        },
        {
          title: 'MÉTODOS DE PRIORIZAÇÃO E SEQUENCIAMENTO',
          time: '17:00',
          items: [
            'Avaliação econômica do esforço',
            'Avaliação econômica do valor de negócio',
            'Avaliação económica do risco',
            'Classes de serviço',
            'Priorizando efetivamente',
            'Fluxo unificado'
          ]
        }
      ]
    },
    {
      title: 'Gestão da capacidade produtiva',
      description: 'Entregar no prazo é um verdadeiro desafio para qualquer tipo de projeto. Neste treinamento, você aprenderá a aplicar análise estatística para, ao invés de usar estimativas, prever as datas de entrega com maior precisão. Esse treinamento abrange o uso das técnicas de gestão mais modernas e lhe auxiliará a realizar suas entregas no prazo.',
      benefits: [
        'Compreensão clara da capacidade produtiva',
        'Maior gerenciabilidade do processo produtivo',
        'Entregas no prazo',
        'Aumento do valor entregue ao cliente',
        'Diminuição do risco'
      ],
      image: 'images/MODULES/MODULE7.png',
      link: 'https://leanit101.com',
      duration: '1hr47m',
      numberOfClasses: '4 ' + t('modules.aulas'),
      classes: [
        {
          title: 'PRINCÍPIOS DE GESTÃO DE CAPACIDADE',
          time: '17:00',
          items: [
            'Tempo de reação',
            'Tempo de ciclo',
            'Tempo de entrega',
            'Tempo de espera',
            'Demanda de falha',
            'Demanda de valor',
            'Mapeamento de fluxo de valor aplicado',
            'Eficiência operacional'
          ]
        },
        {
          title: 'PROCESSOS ÁGEIS APLICADOS À GERENCIAMENTO DE CAPACIDADE',
          time: '25:00',
          items: [
            'Design da solução',
            'Avaliação de risco',
            'Avaliação de valor',
            'Avaliação de esforço',
            'Limite de WIP',
            'Custo do atraso'
          ]
        },
        {
          title: 'FLUXO DE ENTREGA PREVISÍVEL',
          time: '47:00',
          items: [
            'Padronizando o fluxo de desenvolvimento',
            'Acelerando a entrega de valor',
            'Reduzindo a variabilidade',
            'Planning Poker',
            'Story points',
            'Entendo a vazão do fluxo',
            'Usando a lei de Little para prever datas de entrega'
          ]
        },
        {
          title: 'REVISÃO DO MÓDULO',
          time: '18:00',
          items: [
            'Slides',
            'Bibliografia recomendada'
          ]
        }
      ]
    },
    {
      title: 'Scrum',
      description: 'Este treinamento ensinará os principais aspectos do Scrum Framework, fornecendo todas as informações necessárias para começar a usá-lo. Ele cobrirá o fluxo padrão do Scrum, cerimônias, funções, responsabilidades, artefatos e conta também com uma simulação para facilitar seu processo de aprendizado.',
      benefits: [
        'Objetivos claramente definidos',
        'Maior flexibilidade na gestão',
        'Entregas revisadas e garantidas',
        'Reuniões mais produtivas',
        'Ciclos de feedback aprimorados',
        'Entrega no prazo',
        'Coesão da equipe',
        'Crescimento dos indivíduos',
        'Diminuição do risco',
        'Aumento da entrega de valor'
      ],
      image: 'images/MODULES/MODULE8.png',
      link: 'https://leanit101.com',
      duration: '2hr05m',
      numberOfClasses: '7 ' + t('modules.aulas'),
      classes: [
        {
          title: 'FLUXO DAS SPRINTS',
          time: '7:30',
          items: [
            'Estrutura da sprint',
            'Backlog do produto',
            'Backlog da Sprint',
            'Incremento de entrega de valor'
          ]
        },
        {
          title: 'CERIMÔNIAS DE SCRUM',
          time: '24:11',
          items: [
            'Refinamento do backlog',
            'Planejamento de Sprint',
            'Reunião diária',
            'Revisão da Sprint',
            'Retrospectiva da Sprint'
          ]
        },
        {
          title: 'PAPÉIS E RESPONSABILIDADES',
          time: '15:35',
          items: [
            'Product Owner',
            'Scrum Master',
            'Membro da equipe',
            'Equipe Scrum'
          ]
        },
        {
          title: 'ARTEFATOS DO SCRUM',
          time: '23:24',
          items: [
            'Quadro da equipe',
            'Gráfico de Burn Down/Up',
            'Gráfico de fluxo cumulativo'
          ]
        },
        {
          title: 'DISFUNÇÕES DO SCRUM',
          time: '9:15',
          items: [
            'Armadilhas em retrospectivas',
            'Daily Scrum oneroso e Improdutivo',
            'TimeBox não respeitado',
            'Product Owner despreparado',
            'Refinamento ineficiente do backlog',
            'Scrum master não atua como agente de mudanças',
            'Burndown não queima'
          ]
        },
        {
          title: 'SIMULAÇÃO',
          time: '21:26',
          items: [
            'Juntando tudo'
          ]
        },
        {
          title: 'REVISÃO DO MÓDULO',
          time: '23:20',
          items: [
            ''
          ]
        },
        {
          title: 'MATERIAL DE APOIO',
          time: '',
          items: [
            'Slides',
            'Bibliografia recomendada'
          ]
        }
      ]
    },
    {
      title: 'Kanban',
      description: 'O treinamento Kanban permitirá você utilizar o sistema puxado de produção e fluxo contínuo com maior eficácia. Ele cobrirá uma jornada do from-zero-to-hero do uso do kanban, especialmente para o desenvolvimento de produtos de software. Este treinamento também abordará como usar o Kanban para uma abordagem de fluxo unificado que pode ser adotada em qualquer departamento ou estrutura organizacional.',
      benefits: [
        'Aumenta a eficiência e a produtividade do time',
        'Previne a sobrecarga e aumenta a flexibilidade do time',
        'Melhora a comunicação e a entrega de valor',
        'Permite melhor atuação nas filas e gargalos do fluxo',
        'Auxilia o time no processo de melhoria contínua',
        'Estabelece políticas claras de qualidade e gestão',
        'Permite melhor gerenciabilidade com gestão à vista',
        'Habilita o fluxo just-in-time de produção',
        'Aumenta o foco e reduz desperdícios'
      ],
      image: 'images/MODULES/MODULE9.png',
      link: 'https://leanit101.com',
      duration: '1hr37m',
      numberOfClasses: '5 ' + t('modules.aulas'),
      classes: [
        {
          title: 'PRINCÍPIOS DO KANBAN',
          time: '26:50',
          items: [
            'Mapeamento do fluxo de valor',
            'Definição de políticas de fluxo e acordos de trabalho',
            'Visualização do fluxo',
            'Limitação do trabalho em andamento',
            'Melhoramento do fluxo',
            'Garantindo fluxo contínuo',
            'Fluxo de peça única'
          ]
        },
        {
          title: 'APLICAÇÃO PRÁTICA DO KANBAN',
          time: '28:37',
          items: [
            'Do conceito ao dinheiro',
            'Upstream',
            'Downstream',
            'Entrada de fluxo',
            'Inception de solução',
            'Trabalho em progresso',
            'Entrega',
            'Políticas de fluxo',
            'Classes de serviço',
            'Vazão do fluxo'
          ]
        },
        {
          title: 'IMPLEMENTANDO LEAN USANDO O KANBAN',
          time: '13:26',
          items: [
            'Tempo de ciclo',
            'Tempo de espera',
            'Tempo de toque',
            'Tempo de valor agregado',
            'Heijunka',
            'Tamanhos de lote',
            'Lei de Little',
            'Alcançando previsibilidade de entrega'
          ]
        },
        {
          title: 'UM MODELO KANBAN PARA DESENVOLVIMENTO DE SOFTWARE',
          time: '12:44',
          items: [
            'Fluxo unificado',
            'Pipeline Prioritário',
            'Estudo de caso'
          ]
        },
        {
          title: 'REVISÃO DO MÓDULO',
          time: '15:28',
          items: ['Aula de Revisão']
        },
        {
          title: 'MATERIAL DE APOIO',
          time: '4:30',
          items: [
            'Slides',
            'Bibliografia recomendada'
          ]
        }
      ]
    },
    {
      title: 'Retrospectivas Ágeis',
      description: 'A execução contínua de melhorias está no centro do pensamento lean e no coração das metodologias ágeis. Uma das formas de sistematizá-la é por meio de retrospectivas ágeis. Este treinamento explora a estrutura Agile Retrospectives da autora Diana Larsen e fornece ferramentas e práticas para facilitação de retrospectivas ágeis.',
      benefits: [
        'Melhora a produtividade e o entrosamento do time',
        'Auxilia na análise de causa raiz para isolar problemas e evitá-los',
        'Foca em ações de curto prazo para melhorias de longo prazo',
        'Facilita o desenvolvimento das habilidades do time',
        'Aumenta a moral da equipe',
        'Melhora a qualidade dos produtos e serviços',
        'Aumenta o valor entregue pelo time'
      ],
      image: 'images/MODULES/MODULE10.png',
      link: 'https://leanit101.com',
      duration: '1hr',
      numberOfClasses: '3 ' + t('modules.aulas'),
      classes: [
        {
          title: 'POR QUE RETROSPECTIVAS ÁGEIS',
          time: '9:06',
          items: [
            'Melhoria contínua',
            'Kaizen vs Kaikaku',
            'PDCA',
            'Hansei',
            'Inspeção e adaptação'
          ]
        },
        {
          title: 'A ESTRUTURA DA RETROSPECTIVA ÁGIL',
          time: '43:43',
          items: [
            'Definindo o palco',
            'Coletando dados',
            'Gerando insights',
            'Decidindo o que fazer',
            'Fechando a retrospectiva'
          ]
        },
        {
          title: 'DISFUNÇÕES COMUNS EM RETROSPECTIVAS',
          time: '7:26',
          items: [
            'O jeito errado de fazer',
            'Lavação de roupa suja',
            'Falta de segurança psicológica',
            'Ausência de retrospectivas',
            'Foco em pessoas'
          ]
        },
        {
          title: 'MATERIAL DE APOIO',
          time: '',
          items: [
            'Slides',
            'Bibliografia recomendada'
          ]
        }
      ]
    },
    {
      title: 'Gestão a vista',
      description: 'A gestão à vista é uma prática fundamental da filosofia lean e também dos métodos ágeis. Este treinamento explora princípios importantes sobre a gestão visual e também demonstra características comuns que podem ser usadas para potencializar seus resultados com esse modelo de gestão.',
      benefits: [
        'Permite visualizar o fluxo de produção e o desperdício',
        'Substitui relatórios de status por dados reais e atualizados',
        'Fomenta gemba walks e genchi genbutsu',
        'Envolve todos em uma melhor comunicação',
        'Melhora a colaboração e a dinâmica de trabalho do time',
        'Fomenta o processo de melhoria contínua'
      ],
      image: 'images/MODULES/MODULE11.png',
      link: 'https://leanit101.com',
      duration: '41min',
      numberOfClasses: '2 ' + t('modules.aulas'),
      classes: [
        {
          title: 'POR QUE GESTÃO VISUAL',
          time: '14:26',
          items: [
            'Grandes Visuais',
            'Manufatura enxuta',
            'Controles visuais',
            'Métricas Visuais',
            'Você não pode gerenciar o que você não vê',
            'Radiadores de informação',
            'Melhoraria da comunicação',
            'Desenvolvimento e compartilhamento dos padrões de trabalho',
            'Práticas de gestão lean potencializadas',
            'Problemas e o processo de resolução à vista',
            'Visualização de desperdícios',
            'Gestão de filas e gargalos',
            'Gestão da performance'
          ]
        },
        {
          title: 'CARACTERÍSTICAS DE UMA BOA GESTÃO VISUAL PARA EQUIPES ÁGEIS',
          time: '26:48',
          items: [
            'Visualizando o fluxo',
            'Previsão de entrega por meio da análise de fluxo',
            'Compartilhando o status sempre atualizado',
            'Destacando e resolução de problemas',
            'Facilitando o acesso aos padrões de trabalho',
            'Contratos visuais de trabalho'
          ]
        },
        {
          title: 'MATERIAL DE APOIO',
          time: '',
          items: [
            'Slides',
            'Bibliografia recomendada'
          ]
        }
      ]
    },
    {
      title: 'TDD e Clean Code',
      description: 'Codificar é uma arte. Se for feito errado no primeiro momento, seus custos de manutenção crescem exponencialmente ao longo do tempo e tanto a qualidade do produto quanto a qualidade de vida do programador são prejudicadas. Este treinamento ensinará como escrever um código testável, simples e limpo usando desenvolvimento orientado a testes, a fim de ajudar os desenvolvedores a produzir código de qualidade, barato, confiável e fácil de alterar.',
      benefits: [
        'Código mais fácil e mais barato de manter',
        'A refatoração de código é facilitada',
        'Menores custos de desenvolvimento e manutenção',
        'Maior valor entregue e aumento do retorno sobre o investimento',
        'Fomenta o design modular e aumenta a flexibilidade da arquitetura'
      ],
      image: 'images/MODULES/MODULE12.png',
      link: 'https://leanit101.com',
      duration: '2hr',
      numberOfClasses: '4 ' + t('modules.aulas'),
      classes: [
        {
          title: 'A NATUREZA EVOLUCIONÁRIA DO SOFTWARE',
          time: '19:33',
          items: [
            'O ciclo de vida de desenvolvimento de software',
            'Impacto da evolução da tecnologia',
            'Design emergente',
            'Custo da complexidade',
            'Sistemas legados'
          ]
        },
        {
          title: 'CODIFICANDO PARA O VALOR',
          time: '14:26',
          items: [
            'Integridade conceitual',
            'Integridade percebida',
            'Entendendo e medindo o valor',
            'Código para o valor regra nº 1',
            'Código para o valor regra nº 2'
          ]
        },
        {
          title: 'CÓDIGO LIMPO',
          time: '34:12',
          items: [
            'Convenções de nomenclatura',
            'Dimensionamento',
            'Padrões de codificação',
            'Abstração (SOLID)',
            'Documentação do código',
            'Propriedade compartilhada',
            'Código sempre entregável',
            'DRY',
            'KISS',
            'Código cheirando mau'
          ]
        },
        {
          title: 'TDD - DESENVOLVIMENTO DIRIGIDO POR TESTES',
          time: '52:30',
          items: [
            'O poder do feedback antecipado',
            'Benefícios de pequenos lotes',
            'A primeira abordagem de teste',
            'O ciclo de refatoração',
            'Configurando o ambiente de teste',
            'Codificação para o valor com a prática de TDD'
          ]
        },
        {
          title: 'MATERIAL DE APOIO',
          time: '',
          items: [
            'Slides',
            'Bibliografia recomendada'
          ]
        }
      ]
    },
    {
      title: 'Arquitetura Emergente de Software',
      description: 'Garantir a capacidade de se estender com segurança as capacidades de um software requer uma arquitetura sólida. Este treinamento explora prós, contras, além de características e princípios de arquiteturas de software boas e ruins. Baseado no princípio just in time, este treinamento ensina como emergir a sua arquitetura de software com segurança ao longo do tempo, de acordo com as necessidades do seu negócio.',
      benefits: [
        'Sólida fundação para a construção de uma arquitetura escalável, flexível, com alta performance e menor custo de desenvolvimento',
        'Gestão da complexidade facilitada',
        'Redução de desperdícios e maior produtividade no desenvolvimento',
        'Melhor gestão de riscos',
        'Resiliência e adaptabilidade a novos cenários de negócio'
      ],
      image: 'images/MODULES/MODULE13.png',
      link: 'https://leanit101.com',
      duration: '2hr',
      numberOfClasses: '4 ' + t('modules.aulas'),
      classes: [
        {
          title: 'PROBLEMAS COMUNS DE ARQUITETURA DE SOFTWARE',
          time: '25:00',
          items: [
            'Baixa testabilidade - alto custo para testar componentes isolados',
            'Baixa replicabilidade',
            'Grandes bancos de dados raramente disponíveis para desenvolvimento e testes',
            'Arquiteturas baseadas em framework',
            'Tecnologias desatualizadas'
          ]
        },
        {
          title: 'MAUS CHEIROS NA ARQUITETURA DE SOFTWARE',
          time: '31:22',
          items: [
            'Lógica de negócios escrita em componentes de interface',
            'Lógica de negócios dispersa e duplicada',
            'Acoplamento rígido com bancos de dados',
            'Arquiteturas baseadas em SQL',
            'Alto custo para substituir bibliotecas existentes',
            'Evolução engessada devido a estruturas arquitetônicas'
          ]
        },
        {
          title: 'CARACTERÍSTICAS DE UMA BOA ARQUITETURA DE SOFTWARE',
          time: '51:04',
          items: [
            'Definições de arquitetura de software',
            'Isolamento da lógica de negócios',
            'Camadas e responsabilidades definidas',
            'Regras de fluxo definidas',
            'Permitir decisões adiadas',
            'Implementar adaptadores de interface',
            'Interfaces de tratamento de exceção',
            'Implementar validações de interface',
            'Manipulação de transações',
            'Gestão de débito técnico',
            'Recuperação automática de falhas',
            'Infraestrutura versionada como código',
            'Resiliência',
            'Otimização de custos',
            'Confiabilidade',
            'Previsibilidade',
            'Testabilidade',
            'Escalabilidade',
            'Rastreabilidade',
            'Gestão de logs',
            'Auditoria',
            'Camadas',
            'Persistência e distribuição de dados',
            'Processamento distribuído e simultaneidade',
            'Gerenciamento de sessão',
            'Design orientado a domínio',
            'Linguagem onipresente',
            'Design flexível',
            'Componentização',
            'Arquiteturas federadas',
            'Monólitos vs Micro serviços',
            'Arquiteturas reativas',
            'Orientado a eventos',
            'CQRS'
          ]
        },
        {
          title: 'ALÉM DA ARQUITETURA EMERGENTE DE SOFTWARE',
          time: '15:10',
          items: [
            'Modelo de negócios e modelo de licenciamento',
            'Licenciamento de tecnologia',
            'Portabilidade',
            'Mobilidade',
            'Usabilidade',
            'Estratégias de implantação',
            'Distribuição e instalação',
            'Gerenciamento de releases',
            'Segurança',
            'Treinamento e integração',
            'Gestão de configurações',
            'Integrações e extensões'
          ]
        },
        {
          title: 'MATERIAL DE APOIO',
          time: '25:00',
          items: [
            'Slides',
            'Bibliografia recomendada'
          ]
        }
      ]
    },
    {
      title: 'Automação de testes e Docker',
      description: 'Este treinamento explora as facetas de diferentes tipos de automação de teste e se concentrará na construção da infraestrutura virtualizada necessária para executar testes funcionais de API de ponta a ponta. Após o treinamento, você terá a capacidade de criar e manter testes funcionais automatizados.',
      benefits: [
        'Compreensão clara de como montar a infraestrutura de testes funcionais automatizados para testes desde API end-points até o banco de dados',
        'Compreensão de como montar o ambiente de trabalho com docker',
        'Compreensão sobre como realizar migrations de banco de dados automatizadas',
        'Compreensão sobre diferentes tipos de testes',
        'Gestão dos data sets (conjuntos de dados para teste)',
        'O tipo de teste explorado nesse treinamento envolve o mais eficaz teste funcional, com maior cobertura de código, permitindo testar funcionalidades e lógica de negócio em API\'s'
      ],
      image: 'images/MODULES/MODULE14.png',
      link: 'https://leanit101.com',
      duration: '5hr15m',
      numberOfClasses: '7 ' + t('modules.aulas'),
      classes: [
        {
          title: '​​TIPOS DE TESTES',
          time: '49:46',
          items: [
            'Desempenho e carga',
            'Segurança',
            'Tolerância a falhas',
            'Funcional',
            'Unidade',
            'Componente',
            'Integração',
            'Aceitação',
            'Comportamento',
            'Mutação',
            'Exploratório',
            'Usabilidade'
          ]
        },
        {
          title: 'CARACTERÍSTICAS DOS TESTES AUTOMATIZADOS',
          time: '34:37',
          items: [
            'Características de arquiteturas testáveis',
            'Conjunto de dados',
            'Independência',
            'Código legado',
            'Sim, você vai codificar muito mais',
            'Quando automatizar',
            'Quando não automatizar',
            'Responsabilidade pelo teste',
            'Lidando com erros'
          ]
        },
        {
          title: 'VIRTUALIZAÇÃO DA INFRAESTRUTURA COM DOCKER',
          time: '29:15',
          items: [
            'Noções básicas do Docker',
            'Criando e publicando imagens do docker',
            'Gerenciando contêineres docker'
          ]
        },
        {
          title: 'CONFIGURANDO O AMBIENTE DE TRABALHO COM O DOCKER',
          time: '44:26',
          items: [
            'Orquestrando contêineres com docker compose',
            'Configurando o ambiente de desenvolvimento para testes de unidade com o docker'
          ]
        },
        {
          title: 'CONFIGURANDO O AMBIENTE DE TESTES FUNCIONAIS',
          time: '59:44',
          items: [
            'Objetos simulados (mock objects)',
            'Migrações',
            'Helper classes',
            'Testando endpoints autenticados por API',
            'Testando condições de erro',
            'Relatórios de cobertura de código'
          ]
        },
        {
          title: 'GERENCIANDO CONJUNTOS DE DADOS DE TESTES FUNCIONAIS',
          time: '43:01',
          items: [
            'Testando o ciclo de vida dos dados',
            'Características dos conjuntos de dados',
            'Usando bibliotecas de teste externas e seus riscos',
            'Gerenciando dados sementes'
          ]
        },
        {
          title: 'EXPANDINDO AS MIGRAÇÕES',
          time: '54:12',
          items: [
            'Migração automática de banco de dados',
            'Expandindo a estrutura do banco de dados',
            'Garantindo a integridade referencial',
            'Depurando testes funcionais',
            'Exercitando testes funcionais com TDD'
          ]
        },
        {
          title: 'MATERIAL DE APOIO',
          time: '',
          items: [
            'Slides',
            'Bibliografia recomendada'
          ]
        }
      ]
    },
    {
      title: 'Entrega Contínua',
      description: 'Este treinamento se baseia em tudo o que foi explorado nos cursos anteriores da jornada de transformação e ensina os detalhes técnicos passo a passo dos sistemas de entrega contínua, orientando o desenvolvedor na construção de um pipeline de entrega contínua real.',
      benefits: [
        'Compreensão clara passo a passo de como montar um pipeline de entrega contínua, desde a configuração da integração contínua até o servidores de homologação e produção',
        'O continuous delivery ensinado nesse treinamento é o que habilita empresas a usufruírem da possibilidade de, a partir de um único click, realizem com segurança múltiplos releases diários de software em produção'
      ],
      image: 'images/MODULES/MODULE15.png',
      link: 'https://leanit101.com',
      duration: '3hr33m',
      numberOfClasses: '5 ' + t('modules.aulas'),
      classes: [
        {
          title: 'INTRODUÇÃO À ENTREGA CONTÍNUA',
          time: '32:55',
          items: [
            'Evolução das práticas de implantação de software',
            'Efeitos das práticas de implantação manual',
            'Agilidade nos negócios',
            'Repetibilidade e confiabilidade',
            'Automação inteligente',
            'Controle de versão',
            'Construindo com integridade',
            'Propriedade coletiva do código',
            'Fluxo de trabalho de entrega contínua'
          ]
        },
        {
          title: 'ANATOMIA DO BUILD PIPELINE',
          time: '30:17',
          items: [
            'Identificando oportunidades de valor',
            'Validando hipóteses',
            'Design de solução',
            'Mapeamento de estórias',
            'Arquitetura e infraestrutura',
            'Codificação',
            'Validação',
            'Acionando o deploy contínuo'
          ]
        },
        {
          title: 'PRÁTICAS DE ENTREGA CONTÍNUA',
          time: '18:37',
          items: [
            'Gerenciamento de pacotes',
            'Ambientes idênticos',
            'Teste após a implantação',
            'Papéis e responsabilidades',
            'Comportamentos de desenvolvedor desejados',
            'Atualização de versões (upgrade e downgrade)',
            'Lidando com testes quebrados',
            'TDD'
          ]
        },
        {
          title: 'CONFIGURAÇÃO BÁSICA DE ENTREGA CONTÍNUA',
          time: '1:17:43',
          items: [
            'Ferramentas de integração contínua',
            'Ferramentas de controle de versão',
            'Gerenciando o ambiente de desenvolvimento',
            'Práticas de segurança',
            'Configuração de entrega contínua passo a passo'
          ]
        },
        {
          title: 'ENTREGA CONTÍNUA EM AMBIENTES DE HOMOLOGAÇÃO E PRODUÇÃO',
          time: '53:19',
          items: [
            'Configurando ambientes de homologação e produção',
            'Configurações SSH',
            'Gerenciamento de DNS',
            'Revisando o ciclo completo de entrega contínua',
            'Implantando novos recursos no ambiente de produção'
          ]
        },
        {
          title: 'Material de apoio',
          time: '',
          items: [
            'Slides',
            'Bibliografia recomendada'
          ]
        }
      ]
    }
  ]

  return (
    <div
      id='ModulesSection'
      className='w-full md:p-28  bg-vulcan flex flex-col hover:transition-all'
    >
      <div className='md:text-6xl text-2xl font-light py-6 md:pb-24'>
        {t('modules.title')}
        <div className='md:text-2xl text-lg p-3 text-leanItFontColor italic '>
          {t('modules.clique')}
          <span className='font-medium text-leanit101BlueLight4 px-2'>
            {t('modules.treinamentos')}
          </span>
          {t('modules.abaixo')}
          <span className='font-medium text-leanit101BlueLight4 px-2'>
            {t('modules.perceber')}
          </span>
          {t('modules.processo')}
          <p>
            {t('modules.language')}
          </p>
        </div>

      </div>

      {modules.map((element, id) => {
        return (
          <Module
            id={`module_${id + 1}`}
            key={`module_${id + 1}`}
            module={element}
          />
        )
      })}
    </div>
  )
}

export default ModulesSection
