import { useEffect, useState } from 'react'
import '../../input.css'
import axios from 'axios'
import Validator from 'validator'
import i18next from 'i18next'

import { launchLpEvent, launchSubscribedEvent } from './FaceBookEventTracking'
import LeanPyramidHistory from '../AcademySalesPage/LeanPyramidHistory'
import LeanPyramidSection from '../AcademySalesPage/LeanPyramidSection'
import TeacherSection from '../AcademySalesPage/TeacherSection'
const EbookPage = () => {
  i18next.changeLanguage('pt-BR')
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [whatsapp, setWhatsapp] = useState('')

  useEffect(() => {
    launchLpEvent()
    console.log('evento lp')
  }, [])

  const addUserToList = async (email, name, surname, whatsapp) => {
    const data = {
      name,
      email,
      surname,
      whatsapp
    }
    if (Validator.isEmail(email)) {
      if (Validator.isMobilePhone(whatsapp.replace(/\D/g, ''))) {
        if (email && name && surname && whatsapp) {
          const response = await axios.put('/api/contacts/addRootAgilist', data)
          setEmail('')
          setName('')
          setSurname('')
          setWhatsapp('')
          if (response?.data?.message === 'Success') {
            launchSubscribedEvent()
            window.toastr.success(response?.data?.message)
            window.location.href = '/agilista-raiz-thankyou'
          } else {
            window.toastr.error(response?.data?.message)
          }
        } else {
          window.toastr.error('Preencha todos os campos por gentileza!')
        }
      } else {
        window.toastr.error('Por favor insira um whatsapp valido!')
      }
    } else {
      window.toastr.error('Por favor insira um email valido!')
    }
  }

  return (
    <div
      id='CoverPage'
      className='flex md:h-screen h-full text-white flex-col bg-gradient-radial to-steelGray via-leanit101Blue from-leanit101BlueLight4 font-Barlow'
    >
      <div className='bg-vulcan h-16 w-full flex justify-center items-center'>
        <img src='images/logo-leanit101-slogan.png' alt='Logo' className='h-2/3' />
      </div>

      <div className='flex md:flex-row flex-col  justify-between items-center py-4 '>

        <div className='flex flex-col 2xl:flex-row justify-around md:px-24 px-3 w-full items-center'>

          <div className=' mt-4 flex items-center flex-col'>
            <span className='md:text-5xl text-3xl text-start font-black md:max-w-[700px] drop-shadow-2xl'>
              100% ONLINE e GRÁTIS
            </span>
            <div className='md:w-[700px] self-center flex p-4 drop-shadow-2xl'>
              <img src='images/launch/1.png' alt='' />
            </div>
          </div>

          <div className='flex flex-col text-left gap-3 md:w-[600px] h-fit bg-vulcan drop-shadow-2xl border-1 border-solid border-pyramid-19 rounded-xl md:p-12 p-7'>
            <span className='text-3xl font-black'>
              SEMANA DO AGILISTA RAIZ
            </span>
            <span className='text-base '>
              Serão 5 dias de conteúdo cobrindo todo o fluxo de valor do desenvolvimento ágil de software: liderança de equipes, captura de necessidades, design de solução, backlogs de produto, gestão de fluxo e da capacidade produtiva, codigo limpo, testes automatizados, devops, pipeline de entrega contínua, e tudo o que você precisa para entregar valor melhor e mais rápido. Preparado especialmente para:
              <br />
              <br />

              <ul className='font-semibold list-disc list-inside'>
                <li>Desenvolvedores</li>
                <li>Agile Coaches</li>
                <li>Executivos</li>
                <li>Gerentes</li>
              </ul>

              <br />
              Preencha o formulário para iniciar sua inscrição.
            </span>
            <div className='text-w'>
              <form action='' className='flex flex-col text-white gap-4'>
                <div className='flex flex-col font-semibold text-sm'>
                  Nome
                  <input
                    type='text' onChange={(e) => {
                      e.preventDefault()
                      setName(e.target.value)
                    }} value={name} className='rounded-md p-2 text-black mt-1' placeholder='Insira aqui o seu nome'
                  />
                </div>
                <div className='flex flex-col font-semibold text-sm'>
                  Sobrenome
                  <input
                    type='text' onChange={(e) => {
                      e.preventDefault()
                      setSurname(e.target.value)
                    }} value={surname} className='rounded-md p-2 text-black mt-1' placeholder='Insira aqui o seu sobrenome'
                  />
                </div>
                <div className='flex flex-col font-semibold text-sm'>
                  O seu melhor email
                  <input
                    type='text' onChange={(e) => {
                      e.preventDefault()
                      setEmail(e.target.value)
                    }} value={email} className='rounded-md p-2 text-black mt-1' placeholder='Insira aqui o seu melhor email'
                  />
                </div>
                <div className='flex flex-col font-semibold text-sm'>
                  O seu WhatsApp
                  <input
                    type='text' onChange={(e) => {
                      e.preventDefault()
                      const mask = e.target.value.replace(/\D/g, '')
                        .replace(/^(\d)/, '($1')
                        .replace(/^(\(\d{2})(\d)/, '$1) $2')
                        .replace(/(\d{4})(\d{1,5})/, '$1-$2')
                        .replace(/(-\d{5})\d+?$/, '$1')
                      setWhatsapp(mask)
                    }} value={whatsapp} className='rounded-md p-2 text-black mt-1' placeholder='Insira aqui o seu whatsapp'
                  />
                </div>
                <input
                  type='button'
                  className='text-white bg-cyan-600 hover:bg-cyan-500 hover:cursor-pointer font-black rounded-full w-96 mt-5 p-3 px-4 self-center'
                  value='Quero Participar'
                  onClick={() => addUserToList(email, name, surname, whatsapp)}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-gradient-radial to-slate-100 via-slate-50 from-slate-100 flex flex-col  justify-between items-center mt-4 md:mt-0'>
        <div className='bg-slate-200 w-full py-6'>
          <span className='md:text-5xl text-3xl text-center font-black text-gray-400 md:max-w-[700px] drop-shadow-xl'>
            01 de Fevereiro - Aula 1
          </span>

        </div>
        <div className='flex flex-col 2xl:flex-row justify-center md:px-24 w-full items-start py-0 md:py-8'>

          <div className='flex flex-col'>
            <div className='lg:w-[400px] md:w-[700px] self-end flex drop-shadow-2xl'>
              <img src='images/launch/5.png' alt='' />
            </div>
          </div>

          <div className='flex flex-col text-left gap-3 md:w-[600px] h-fit lg:h-[370px] drop-shadow-2xl rounded-xl p-12'>
            <span className='text-3xl font-black text-gray-700'>
              LEAN E AGILE APLICADOS NA GESTÃO DA ESTRATÉGIA DO NEGÓCIO
            </span>
            <span className='text-base text-ebony'>
              <ul className='font-semibold list-disc list-inside'>
                <li>Princípios e Valores</li>
                <li>Formação de equipes e Liderança</li>
                <li>Estruturas funcionais ágeis</li>
                <li>Gestão da estratégia do negócio</li>
              </ul>

            </span>
          </div>
        </div>
      </div>
      <div className='bg-gradient-radial to-slate-100 via-slate-50 from-slate-100 flex flex-col  justify-between items-center py-4 '>
        <div className='bg-slate-200 w-full py-6'>
          <span className='md:text-5xl text-3xl text-center font-black text-gray-400 md:max-w-[700px] drop-shadow-xl'>
            02 de Fevereiro - DINÂMICA DE GRUPO
          </span>

        </div>
        <div className='flex flex-col 2xl:flex-row justify-center md:px-24 w-full items-start py-0 md:py-8'>

          <div className='flex flex-col'>
            <div className='lg:w-[400px] md:w-[700px] self-end flex drop-shadow-2xl'>
              <img src='images/launch/6.png' alt='' />
            </div>
          </div>
          <div className='flex flex-col text-left gap-3 md:w-[600px] h-fit lg:h-[370px] drop-shadow-2xl rounded-xl p-12'>
            <span className='text-3xl font-black text-gray-700'>
              ESTUDO DE CASO REAL GESTÃO DE ESTRATÉGIA
            </span>
            <span className='text-base text-ebony'>
              <ul className='font-semibold list-disc list-inside'>
                <li>Dinâmica exclusiva para o grupo VIP do Whatsapp</li>
                <li>Avaliação de cenário </li>
                <li>Código legado vs Business Agility </li>
                <li>Gestão da estratégia do negócio</li>
                <li>Gestão de mudança</li>
              </ul>
            </span>
          </div>
        </div>
      </div>
      <div className='bg-gradient-radial to-slate-100 via-slate-50 from-slate-100 flex flex-col  justify-between items-center py-4 '>
        <div className='bg-slate-200 w-full py-6'>
          <span className='md:text-5xl text-3xl text-center font-black text-gray-400 md:max-w-[700px] drop-shadow-xl'>
            03 de Fevereiro - Aula 2
          </span>

        </div>
        <div className='flex flex-col 2xl:flex-row justify-center md:px-24 w-full items-start py-0 md:py-8'>

          <div className='flex flex-col'>
            <div className='lg:w-[400px] md:w-[700px] self-end flex drop-shadow-2xl'>
              <img src='images/launch/7.png' alt='' />
            </div>
          </div>

          <div className='flex flex-col text-left gap-3 md:w-[600px] h-fit lg:h-[370px] drop-shadow-2xl rounded-xl p-12'>
            <span className='text-3xl font-black text-gray-700'>
              GESTÃO TÁTICA E OPERACIONAL
            </span>
            <span className='text-base text-ebony'>
              <ul className='font-semibold list-disc list-inside'>
                <li>Desenvolvimento de Produto</li>
                <li>Gestão de backlogs e estórias do usuário</li>
                <li>Gestão da capacidade produtiva</li>
                <li>Gestão de valor e priorização</li>
              </ul>

            </span>
          </div>
        </div>
      </div>
      <div className='bg-gradient-radial to-slate-100 via-slate-50 from-slate-100 flex flex-col  justify-between items-center py-4 '>
        <div className='bg-slate-200 w-full py-6'>
          <span className='md:text-5xl text-3xl text-center font-black text-gray-400 md:max-w-[700px] drop-shadow-xl'>
            04 de Fevereiro - DINÂMICA DE GRUPO
          </span>

        </div>
        <div className='flex flex-col 2xl:flex-row justify-center md:px-24 w-full items-start py-0 md:py-8'>

          <div className='flex flex-col'>
            <div className='lg:w-[400px] md:w-[700px] self-end flex drop-shadow-2xl'>
              <img src='images/launch/8.png' alt='' />
            </div>
          </div>
          <div className='flex flex-col text-left gap-3 md:w-[600px] h-fit lg:h-[370px] drop-shadow-2xl rounded-xl p-12'>
            <span className='text-3xl font-black text-gray-700'>
              ESTUDO DE CASO REAL DE GESTÃO DE PRODUTO
            </span>
            <span className='text-base text-ebony'>
              <ul className='font-semibold list-disc list-inside'>
                <li>Dinâmica exclusiva para o grupo VIP do Whatsapp</li>
                <li>Avaliação de cenário </li>
                <li>Desenvolvimento de produto</li>
                <li>Experiência do usuário</li>
                <li>Excelência Técnica</li>
              </ul>

            </span>
          </div>
        </div>
      </div>
      <div className='bg-gradient-radial to-slate-100 via-slate-50 from-slate-100 flex flex-col  justify-between items-center py-4 '>
        <div className='bg-slate-200 w-full py-6'>
          <span className='md:text-5xl text-3xl text-center font-black text-gray-400 md:max-w-[700px] drop-shadow-xl'>
            05 de Fevereiro - Aula 3
          </span>

        </div>
        <div className='flex flex-col 2xl:flex-row justify-center md:px-24 w-full items-start py-0 md:py-8'>

          <div className='flex flex-col'>
            <div className='lg:w-[400px] md:w-[700px] self-end flex drop-shadow-2xl'>
              <img src='images/launch/9.png' alt='' />
            </div>
          </div>

          <div className='flex flex-col text-left gap-3 md:w-[600px] h-fit lg:h-[370px] drop-shadow-2xl rounded-xl p-12'>
            <span className='text-3xl font-black text-gray-700'>
              LEAN E AGILE APLICADOS NO DESENVOLVIMENTO DA ENGENHARIA DE SOFTWARE
            </span>
            <span className='text-base text-ebony'>
              <ul className='font-semibold list-disc list-inside'>
                <li>Arquitetura emergente</li>
                <li>Clean Code </li>
                <li>Testes Automatizados</li>
                <li>Integração contínua</li>
                <li>Entrega contínua</li>
              </ul>
            </span>
          </div>
        </div>
      </div>
      <div>
        <div className='bg-slate-200 w-full py-6'>
          <span className='md:text-5xl text-3xl text-center font-black text-gray-400 md:max-w-[700px] drop-shadow-xl'>
            MÉTODO RAÍZ
          </span>

        </div>
        <LeanPyramidHistory />
        <LeanPyramidSection />
        <TeacherSection />

      </div>
    </div>
  )
}

export default EbookPage
