import '../../input.css'
import Footer from '../AcademySalesPage/Footer'

const Policy = ({ text, name }) => {
  return (
    <div>
      <div className='bg-vulcan h-16 w-full flex justify-center items-center '>
        <a className='h-16' href='/'>
          <img src='images/logo-leanit101-slogan.png' alt='Logo' className='h-full' />
        </a>
      </div>
      <div className='p-[1rem] px-6 md:px-48 bg-steelGray font-Barlow text-left md:py-32 py-5'>
        <div className='text-white md:text-5xl text-2xl py-12'>
          {name}
        </div>
        <div className='text-slate-500 md:text-xl  text-base'>
          {text}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Policy
