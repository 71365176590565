import './LandingPageWaste.scss'

const LandingPageWaste = () => {
  return (
    <div className='LandingPageWaste'>

      <div
        className='d-flex ice' style={{
          minHeight: 'max(41.6vw, 350px)'

        }}
      >
        <div className='container section-container'>

          <div className='row'>
            <div className='col-md-12 black-text text-base-left mt-3 md-p-5 mb-5 wow fadeInLeft' data-wow-delay='0.3s'>
              <h3 className=''>
                <span className='black '>Existem</span>
                <span className='orange '>{' 7 desperdícios capitais '}</span> <br />
                <span className='orange '>{'que estão te impedindo '}</span>
                <span className='black '>de gerar valor para o seu cliente</span>
              </h3>
            </div>
          </div>

          <div className='row justify-content-center align-items-center mt-3 center'>
            <div className='col-md-3 col-6'>
              <div className='m-2'>
                <img className='' src='/images/landing-lean-agile-foundation/icons/defeito.png' alt='' width='120vw' />
                <h5 className=''> Defeitos</h5>
              </div>
            </div>
            <div className='col-md-3 col-6'>
              <div className=' m-2'>
                <img className='' src='/images/landing-lean-agile-foundation/icons/espera.png' alt='' width='120vw' />
                <h5 className=''> Espera</h5>
              </div>
            </div>
            <div className='col-md-3 col-6'>
              <div className=' m-2'>
                <img src='/images/landing-lean-agile-foundation/icons/estoque.png' alt='' width='120vw' />
                <h5 className=''> Estoque</h5>
              </div>
            </div>
            <div className='col-md-3 col-6'>
              <div className=' m-2'>
                <img src='/images/landing-lean-agile-foundation/icons/movimentacao.png' alt='' width='120vw' />
                <h5 className=''> Movimentação</h5>
              </div>
            </div>
            <div className='col-md-3 col-6'>
              <div className=' m-2'>
                <img src='/images/landing-lean-agile-foundation/icons/processamentoExcessivo.png' alt='' width='120vw' />
                <h5 className=''> Processamento Excessivo</h5>
              </div>
            </div>
            <div className='col-md-3 col-6'>
              <div className=' m-2'>
                <img src='/images/landing-lean-agile-foundation/icons/superproducao.png' alt='' width='120vw' />
                <h5 className=''> Superprodução</h5>
              </div>
            </div>
            <div className='col-md-3 col-6'>
              <div className=' m-2'>
                <img src='/images/landing-lean-agile-foundation/icons/transporte.png' alt='' width='120vw' />
                <h5 className=''> Transporte</h5>
              </div>
            </div>

          </div>
          <div className='row'>

            <div className='col-md-12 text-base-left mt-5 md-p-5 mb-5 wow fadeInLeft' data-wow-delay='0.3s'>

              <h3 className='font-weight-bold text-center md-h4-responsive sm-h5-responsive text'>
                <span className='black '>A melhor forma de</span>
                <span className='orange '>{' eliminar desperdícios '}</span>
                <span className='black '>é através de um</span>
                <span className='blue '>{' método validado '}</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPageWaste
