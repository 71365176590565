import './LandingPageCover.scss'
import '../../../input.css'
const LandingPageCover = () => {
  return (
    <div className='LandingPageCover'>

      <div
        className='full-page-intro vwh-100 d-flex justify-content-center align-items-center bg-image' style={{
          backgroundImage: "url('/images/landing-lean-agile-foundation/coverLp.png')",
          backgroundRepeat: 'no-repeat',
          minHeight: 'max(41.6vw, 350px)',
          backgroundSize: 'cover',
          backgroundPosition: '20% center',
          opacity: '1'
        }}
      >
        <div className='container px-4'>

          <div className='flex-row'>
            <div className='col-md-6 white-text text-center text-base-left mt-5 md-p-5 mb-5  fadeInLeft' data-wow-delay='0.3s'>
              <h2 className='blue'> ALCANCE ATÉ 10X MAIS RESULTADOS</h2>
              <h2 className='  white mb-4'> COM O MÉTODO QUE MUDOU O MUNDO</h2>
              <h4 className='text-center text '>
                <span className='white '>
                  {'Aprenda toda a essência do Lean e do Agile para transformar a sua organização e '}
                </span>
                <span className='blue textshadow'>
                  entregar mais valor para os seus clientes em menos tempo
                </span>
              </h4>
              <button
                onClick={() => {
                  window.location.href = 'https://academy.leanit101.com/leanandagilefoundationb2c'
                }}
                id='compre' className='LandingPageCover-buy mt-4 float-left buy-button' target='_blank'
              >
                <span className=''>
                  COMPRE AGORA
                </span>
              </button>

              <h3 className='white mt-3'>
                POR R$297,00
              </h3>
              <h5 className='white'> ou 12x de R$29,90 </h5>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPageCover
