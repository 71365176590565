import { useTranslation } from 'react-i18next'
import '../../input.css'

const CtaButton = () => {
  const [t] = useTranslation()
  return (
    <button
      onClick={() => {
        window.location.href = 'https://pay.hotmart.com/I74074421T?off=i3wfklt8'
      }}
      id='academy-button'
      className='flex md:justify-start justify-center'
      target='_blank'
    >
      <span className='p-3 px-4 rounded-2xl text-2xl bg-orange-500 font-extrabold hover:bg-orange-400 drop-shadow-lg'>
        {t('sticky_cta.button')}
      </span>
    </button>
  )
}

export default CtaButton
