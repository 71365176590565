import { useEffect } from 'react'
import Router from './components/Router/Router.jsx'
import { I18nextProvider } from 'react-i18next'
import { i18n } from './i18n/i18n'

import './App.css'
const App = () => {
  useEffect(() => {
    window.toastr.options.progressBar = true
    window.toastr.options.closeDuration = 700
    window.toastr.options.timeOut = 10000
    window.toastr.options.extendedTimeOut = 3000
    window.toastr.options.closeButton = true
    window.toastr.options.positionClass = 'toast-bottom-right'
    window.toastr.options.showMethod = 'slideDown'
    window.toastr.options.hideMethod = 'slideUp'
    window.toastr.options.closeMethod = 'slideUp'
    window.toastr.options.closeHtml =
      '<button>x</button>'
  }, [])

  return (
    <div className='App'>
      <I18nextProvider i18n={i18n}>
        <Router />
      </I18nextProvider>
      <header className='App-header' />
    </div>
  )
}

export default App
