import '../../input.css'
import Policy from './Policy'

const termsOfUse = (
  <div>
    <div>O presente instrumento particular de “Termos de Uso” regula os direitos e obrigações relacionados ao uso do Site e Ambiente Virtual de Cursos e Comunidade da Lean it 101 (“Plataforma”), disponível em https://leanit101.com, celebrado entre a LEANIT101 SERVIÇOS EM INFORMÁTICA, inscrita no CNPJ sob o número 22.724.260/0001-42, com sede na Rua Capri, nº 465, apto 302, Passa Vinte, Palhoça/SC - CEP 88132-229, contato eletrônico: contato@leanit101.com denominada como CONTRATADA; e “Você”, na qualidade de “Aluno”, “Usuário” ou “Visitante”, denominado como CONTRATANTE, nos termos a seguir:</div>
    <br />
    <br />
    <div>DAS DEFINIÇÕES</div>
    <br />
    <div>No intuito de orientar a interpretação dos termos do presente contrato, deve-se considerar as seguintes definições</div>
    <br />
    <div>- “Aluno”: trata-se do Usuário que está realizando o(s) Curso(s);</div>
    <br />
    <div>- “Conteúdo”: todo material disponibilizado na Plataforma, tais como videoaulas, materiais de apoio, textos, apostilas, avaliações, atividades, simulados, modelos de maturidade para avaliação, dentre outros;</div>
    <br />
    <div>- “Plataforma”: Site, comunidade e qualquer ambiente virtual de Aprendizagem ou de Avaliação (assessment) da Lean it 101;</div>
    <br />
    <div>- “<a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-privacidade'> Política de Privacidade </a>”: regras estatuídas pela Lean it 101 para proteção da privacidade do Usuário, bem como para trazer transparência acerca do tratamento dos dados e informações pessoais que são disponibilizados na Plataforma;</div>
    <br />
    <div>- “<a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-protecao-aos-direitos-autorais'> Política de Proteção aos Direitos Autorais </a>”: regras estatuídas pela Lean it 101 para orientar “Você” sobre a proteção à propriedade intelectual conferida pela Lei de Direitos Autorais, pela Constituição Federal, e demais legislações aplicáveis;</div>
    <br />
    <div>- “Usuário”: pessoa física, capaz, ou jurídica, por seu representante, que se cadastra na Plataforma e dela faz uso, a partir de um login e senha;</div>
    <br />
    <div>- “Você”: pessoa física ou jurídica que acessa, se cadastra, adquire cursos e/ou faz uso, de um modo geral, da Plataforma, na qualidade de “Aluno”, “Usuário” e/ou “Visitante”.</div>
    <br />
    <br />
    <div>DO OBJETO</div>
    <br />
    <div>Estes “Termos de Uso” visam regular a utilização da Plataforma pelo CONTRATANTE, conforme estipulado nos itens a seguir.</div>
    <br />
    <br />
    <div>DA ACEITAÇÃO DO CONTRATANTE</div>
    <br />
    <div>A aceitação destes Termos de Uso pelo Usuário se dará no ato do seu clique no botão “Eu li e concordo”, ao se cadastrar na Plataforma e, em relação ao CONTRATANTE, quando faz a navegação e utilização da Plataforma.</div>
    <br />
    <div>Caso o potencial CONTRATANTE não concorde com o presente “Termos de Uso”, não poderá realizar a aquisição de nenhum produto, devendo se abster de acessar e utilizar a plataforma de ensino por não figurar como CONTRATANTE.</div>
    <br />
    <div>No acesso, navegação, cadastro e/ou utilização da Plataforma, aplicam-se as disposições constantes na <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-privacidade'> Política de Privacidade </a> e <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-protecao-aos-direitos-autorais'> Política de Proteção aos Direitos Autorais </a>, conjuntamente com estes “Termos de Uso”.</div>
    <br />
    <div>Ao acessar e se cadastrar na Plataforma, o CONTRATANTE declara e deve ter capacidade jurídica para atos civis e deverá, necessariamente, prestar as informações exigidas no CADASTRO, assumindo integralmente a responsabilidade (inclusive cível e criminal) pela exatidão e veracidade das informações fornecidas no CADASTRO (que poderá ser verificado, a qualquer momento), atestando ainda ser capaz para compreender, aceitar e cumprir estes Termos de Uso, a <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-privacidade'> Política de Privacidade </a>, e a <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-protecao-aos-direitos-autorais'> Política de Proteção aos Direitos Autorais </a>.</div>
    <br />
    <br />
    <div>DAS CONDIÇÕES GERAIS DE ACESSO E UTILIZAÇÃO DA PLATAFORMA</div>
    <br />
    <div>O acesso à Plataforma será liberado ao CONTRATANTE após o preenchimento completo dos dados cadastrais, com todas as informações obrigatórias solicitadas pela CONTRATADA e com a criação do respectivo login e senha para acesso.</div>
    <br />
    <div>A CONTRATADA, a seu exclusivo critério, poderá solicitar dados adicionais para identificação do CONTRATANTE tanto como pré-requisito para formalização da compra quanto para a manutenção do seu acesso ou ainda para oferecer uma melhor segurança.</div>
    <br />
    <div>O CONTRATANTE é o único responsável pelas informações por ele fornecidas quando de seu cadastro na Plataforma, estando ciente de que a CONTRATADA não se responsabiliza por informações incorretas ou inverídicas fornecidas pelo CONTRATANTE, o qual será responsável, também, por manter atualizadas todas as informações pessoais e de contato fornecidas.</div>
    <br />
    <div>O CONTRATANTE reconhece que, caso seja constatado que este forneceu informações incorretas ou inverídicas em seu cadastro, seu acesso à Plataforma poderá ser cancelado, independentemente de qualquer formalidade, sem que nada seja devido pela CONTRATADA, em razão de tal cancelamento.</div>
    <br />
    <div>A segurança da senha cadastrada fornecida é de responsabilidade exclusiva do CONTRATANTE, que não deverá compartilhá-la com terceiros, sendo o mesmo o único responsável por eventual uso inadequado decorrente de acessos indevidos.</div>
    <br />
    <div>Havendo suspeita de conhecimento da senha por terceiros, o CONTRATANTE deverá comunicar, imediatamente, a CONTRATADA para a substituição das informações de acesso. Vale ressaltar que o login e senha do CONTRATANTE são pessoais e intransferíveis, motivo pelo qual se forem usados por terceiros, a CONTRATADA se reserva o direito de cancelar seu acesso.</div>
    <br />
    <div>A CONTRATADA através de seus meios de monitoramento e sistemas de segurança e controle, poderá identificar acessos simultâneos do Aluno, ensejando a suspensão da transmissão do Curso.</div>
    <br />
    <div>Por meio do uso de ferramentas de Tecnologia da Informação e Comunicação – TIC, o descumprimento dos itens presentes no presente Termos de Uso, a <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-privacidade'> Política de Privacidade </a>, e a <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-protecao-aos-direitos-autorais'> Política de Proteção aos Direitos Autorais </a>, podem acarretar em suspenção preventivamente o acesso do CONTRATANTE à plataforma de ensino, requerendo esclarecimentos para o fato constatado; e, não sendo apresentado ou sendo insuficientes os esclarecimentos prestados, cancelar definitivamente o acesso do CONTRATANTE à plataforma de ensino, sem a devolução de quaisquer valores.</div>
    <br />
    <div>O CONTRATANTE está ciente de que o mero cadastro na Plataforma não fornecerá acesso aos Cursos da CONTRATADA, os quais só serão disponibilizados após comprovação de pagamento.</div>
    <br />
    <div>Com a aquisição do Curso, o CONTRATANTE receberá o status de Aluno e lhe será permitido acessar as videoaulas, previamente gravadas, e outros Conteúdos do disponibilizados, conforme cronograma disponibilizado pela CONTRATADA.</div>
    <br />
    <div>A transmissão do Curso ao CONTRATANTE ocorrerá, exclusivamente, na Plataforma, sendo proibido o armazenamento, download ou gravação das videoaulas.</div>
    <br />
    <div>A CONTRATADA não disponibiliza suas videoaulas em DVDs, pendrives ou qualquer tipo de mídia de armazenamento, devendo o acesso ao Conteúdo ocorrer apenas na Plataforma.</div>
    <br />
    <div>Fica sob responsabilidade do CONTRATANTE se atentar ao período de acesso que este tem direito (explícito no momento da contratação), sendo que após o período especificado, o CONTRATANTE não terá mais permissão para acessar o conteúdo. Este também deve se atentar às datas do cronograma disponibilizado pela CONTRATADA na Plataforma.</div>
    <br />
    <div>Você deverá se atentar às regras específicas para participação no(s) Evento(s) organizado(s) pela CONTRATADA que seja(m) de seu interesse, observando as informações e datas divulgadas na Plataforma.</div>
    <br />
    <div>O serviço disponibilizado na Plataforma depende da funcionalidade simultânea de diversos fatores, alguns alheios ao controle da CONTRATADA, tais como a interação de servidores e serviços de telecomunicações de terceiros, a adequação dos equipamentos do CONTRATANTE, competindo ao mesmo observar os requisitos mínimos para acesso ao serviço almejado.</div>
    <br />
    <div>A CONTRATADA praticará a política de "GARANTIA DE SATISFAÇÃO OU DINHEIRO DE VOLTA", pela qual o CONTRATANTE poderá solicitar o cancelamento da compra e a restituição do valor investido na aquisição de um produto da CONTRATADA, observadas as seguintes condições:</div>
    <br />
    <ul className='list-decimal list-inside pl-10'>
      <li>Quando da compra de um curso, o CONTRATANTE poderá solicitar o cancelamento e a restituição integral do valor da compra à CONTRATADA, até o 7º (sétimo) dia após a disponibilização de acesso quando se tratar dos produtos Academy;</li>
      <li>Em se tratando de pagamento por meio de boleto bancário, a restituição dos valores ocorrerá em até 20 (vinte) dias úteis, a contar do aceite pela CONTRATADA quanto ao fornecimento dos dados bancários corretos pelo CONTRATANTE;</li>
      <li>Tratando-se de pagamento por meio de cartão de crédito, a restituição dependerá da data de fechamento da fatura do CONTRATANTE e da data do reconhecimento da solicitação de cancelamento pelo Banco Emissor do CONTRATANTE</li>
    </ul>
    <br />
    <div>O CONTRATANTE, após finalizar o cadastramento, caso não consiga fazer diretamente na plataforma, poderá alterar seus dados pessoais por meio de solicitação para contato@leanit101.com, podendo a CONTRATADA solicitar informações adicionais para as efetivar.</div>
    <br />
    <div>Se for verificado que a conta não foi regularmente cadastrada ou não está sendo usada de forma adequada, como nas situações previstas, poderá ocorrer a suspensão de acesso.</div>
    <br />
    <div>A CONTRATADA poderá compartilhar o Conteúdo de Aluno com suas afiliadas, seus sócios, administradores, empregados, terceiros prestadores de serviços, sociedades controladoras, coligadas e controladas, bem como todas as outras pessoas ou entidades que, ao exclusivo e razoável critério da CONTRATADA, contribuam para o funcionamento da Plataforma, incluindo empresas de atendimento ao cliente, faturamento ou cobrança, que a CONTRATADA tenha contratado, sempre de acordo com a <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-privacidade'> Política de Privacidade </a> e com a Lei Geral de Proteção de Dados.</div>
    <br />
    <br />
    <div>DAS NORMAS DE CONDUTA E PROTEÇÃO À PROPRIEDADE IMATERIAL</div>
    <br />
    <div>Reconhecendo o alcance mundial da Internet, o CONTRATANTE concorda em cumprir qualquer legislação do local onde está situado, bem como as leis vigentes na sede da CONTRATADA, no Brasil e, ainda, a respeitar o disposto nestes Termos de Uso, <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-privacidade'> Política de Privacidade </a> e <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-protecao-aos-direitos-autorais'> Política de Proteção aos Direitos Autorais </a>.</div>
    <br />
    <div>O CONTRATANTE se compromete a não produzir, reproduzir, disponibilizar, divulgar ou transmitir qualquer conteúdo que: (i) Seja contrário a qualquer norma da legislação brasileira, bem como à moral e aos bons costumes normalmente aceitos, ou que incentive qualquer forma de racismo, discriminação ou violência; (ii) Seja protegido por quaisquer direitos de propriedade intelectual ou industrial pertencente a terceiros, sem que Você tenha obtido previamente dos seus titulares a autorização necessária para levar a cabo o uso que efetuar ou pretender efetuar; (iii) Incorporem códigos maliciosos ou outros elementos físicos ou eletrônicos que possam gerar danos ou impedir o normal funcionamento da rede, do sistema ou de equipamentos informáticos (hardware e software) da CONTRATADA ou de terceiros, ou que possam causar dano aos documentos eletrônicos e arquivos armazenados nestes equipamentos informáticos; (iv) Provoquem, por suas características (tais como forma, extensão etc.) dificuldades no normal funcionamento do serviço.</div>
    <br />
    <div>O CONTRATANTE reconhece que, em qualquer hipótese, será o único responsável pelo uso que fizer da Plataforma, bem como por qualquer conteúdo ou comentário que nela inserir.</div>
    <br />
    <div>O acesso a áreas restritas dentro da Plataforma somente é permitido aos CONTRATANTES devidamente cadastrados, a partir do uso de seu login e senha, sendo tais áreas consideradas fechadas. Neste sentido, o CONTRATANTE fica ciente que se alguma falha no sistema for encontrada que permita o seu acesso a qualquer área restrita da Plataforma, ainda que por mera tentativa de erro e acerto de senha, ainda assim o CONTRATANTE incidirá em sanções civis e criminais decorrentes de sua conduta.</div>
    <br />
    <div>O CONTRATANTE poderá inserir fotos em seu perfil na Plataforma, ou quando participa de campanhas da CONTRATANTE, momento em que conferirá autorização de divulgação de tal imagem, porém (a) somente poderá inserir fotos de sua autoria; e (b) não poderá inserir fotos de terceiros, nudez, ou outro material protegido por direitos autorais e de imagem. Portanto, o CONTRATANTE se responsabiliza pelas fotos que utilizar.</div>
    <br />
    <div>Todo conteúdo disponibilizado na Plataforma, como marcas, logotipos, vídeos, arquivos, textos, ícones, desenhos, sons, layouts, materiais didáticos, algoritmos, incluindo-se os Cursos, são de propriedade exclusiva da CONTRATADA, ou de terceiros que concederam autorização para tal utilização, e estão protegidos pelas leis e tratados internacionais, sendo vedada sua cópia, reprodução, ou qualquer outro tipo de utilização, ficando os infratores sujeitos às sanções civis e criminais correspondentes, nos termos das Leis 9.279/96, 9.610/98, 9.609/98 e 2.848/40, conforme detalhado na <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-protecao-aos-direitos-autorais'> Política de Proteção aos Direitos Autorais </a>.</div>
    <br />
    <div>O CONTRATANTE deverá utilizar a Plataforma e todo o Conteúdo nela disponibilizado, incluindo os Cursos, de acordo com o ordenamento jurídico brasileiro, com a moral e os bons costumes geralmente aceitos, com os presentes Termos de Uso, <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-privacidade'> Política de Privacidade </a>, <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-protecao-aos-direitos-autorais'> Política de Proteção aos Direitos Autorais </a> e as demais instruções existentes, abstendo-se de usar, explorar, reproduzir ou divulgar, indevidamente, por qualquer meio, o conteúdo disponibilizado na Plataforma.</div>
    <br />
    <div>Todas as marcas, nomes comerciais ou logotipos de qualquer espécie, disponibilizados na Plataforma, são de propriedade da CONTRATADA, sem que a utilização da Plataforma possa ser entendida como autorização para que o CONTRATANTE possa citar as tais marcas, os nomes comerciais e logotipos.</div>
    <br />
    <div>O(s) Instrutor(es) autorizaram a CONTRATADA a utilizar sua imagem nos Cursos e Eventos. Desse modo, qualquer reprodução indevida dos Cursos e Eventos disponibilizados constitui, além da violação de direitos de propriedade intelectual da CONTRATADA, a violação dos direitos de imagem do(s) respectivo(s) Instrutor(es). Caso o CONTRATANTE faça eventual uso indevido da imagem do(s) Instrutor(es) terá responsabilidade exclusiva pela reparação civil, sem prejuízo da indenização por todos os danos e despesas da CONTRATADA.</div>
    <br />
    <div>Todos os Cursos e Conteúdos que são disponibilizados para o CONTRATANTE são apenas no estado em que se encontram e tão somente para sua informação e uso pessoal na forma designada pela CONTRATADA. Tais Cursos e Conteúdos não podem ser repassados, copiados, rateados, reproduzidos, distribuídos, transmitidos, difundidos, exibidos, vendidos, licenciados, adaptados ou, de outro modo, explorados para quaisquer fins, sem o consentimento prévio e por escrito da CONTRATADA.</div>
    <br />
    <br />
    <div>DO PROCEDIMENTO EM CASO DE CONSTATAÇÃO DE ABUSOS OU IRREGULARIDADES</div>
    <br />
    <div>Caso o CONTRATANTE identifique qualquer material ofensivo, ilegal, ou atentatório à moral e aos bons costumes, disponibilizado por outro Usuário da Plataforma, poderá, imediatamente comunicar a CONTRATADA, através do e-mail contato@leanit101.com, para que possa ser apurada essa denúncia, ficando a CONTRATADA isenta de qualquer responsabilidade por tal conteúdo, por ter sido realizado por terceiros, sem qualquer intervenção ou controle da mesma.</div>
    <br />
    <div>Ficará ao critério da administração da CONTRATADA a apuração das denúncias que lhe forem dirigidas.</div>
    <br />
    <div>O comportamento ilícito poderá ser sancionado com a suspensão ou cancelamento do cadastro do Usuário na Plataforma, sem prejuízo da adoção das medidas judiciais cabíveis.</div>
    <br />
    <br />
    <div>DA DURAÇÃO E FINALIZAÇÃO DO ACESSO À PLATAFORMA/COMUNIDADE</div>
    <br />
    <div>O acesso à Plataforma é concedido ao CONTRATANTE por prazo indeterminado. É assegurado a CONTRATADA, no entanto, o direito de terminar, suspender ou interromper, unilateralmente e a qualquer momento, o acesso à Plataforma, sem que qualquer indenização seja devida ao CONTRATANTE.</div>
    <br />
    <div>A CONTRATADA, ainda, se reserva o direito de recusar ou retirar o acesso à Plataforma, a qualquer momento, e sem necessidade de prévio aviso, por iniciativa própria ou por exigência de um terceiro, se o CONTRATANTE descumprir, de qualquer forma, estes Termos de Uso, as Políticas de Privacidade e Proteção aos Direitos Autorais e/ou a legislação vigente.</div>
    <br />
    <div>O CONTRATANTE reconhece que a CONTRATADA pode, a qualquer tempo, remover da Plataforma qualquer Curso ou Conteúdo disponibilizado, sem necessidade de aviso prévio e sem que nenhuma indenização seja devida ao CONTRATANTE em razão de tal remoção, com exceção das hipóteses expressamente previstas.</div>
    <br />
    <div>No caso de conteúdos pagos, é responsabilidade do CONTRATANTE ficar atento aos prazos, datas e períodos de acesso explícitos no momento da contratação. A CONTRATADA, por sua vez, tem o dever de explicitar essas informações.</div>
    <br />
    <br />
    <div>DA EXCLUSÃO DE GARANTIAS E DA RESPONSABILIDADE DA CONTRATADA</div>
    <br />
    <div>A CONTRATADA não será, em hipótese alguma, responsável por quaisquer danos decorrentes da interrupção do acesso à Plataforma ou falhas no seu funcionamento.</div>
    <br />
    <div>A CONTRATADA utiliza as práticas recomendadas para manter seguros todos os dados inseridos por pelo CONTRATANTE em suas Plataformas, Comunidades e Cadastros, porém a mesma se exime de ser responsabilidade por eventuais danos e prejuízos de toda natureza que decorram do conhecimento que terceiros não autorizados tenham acesso a quaisquer informações passadas pelo CONTRATANTE em decorrência de falha exclusivamente atribuível ao CONTRATANTE ou a terceiros que fujam a qualquer controle razoável da CONTRATADA.</div>
    <br />
    <div>A CONTRATADA não garante a ausência de softwares maliciosos quando da utilização de sua Plataforma, bem como outros elementos nocivos que possam produzir alterações nos sistemas informáticos dos Usuários e CONTRATANTES (software e hardware) ou nos documentos eletrônicos armazenados no sistema informático, eximindo-se de qualquer responsabilidade pelos danos e prejuízos que possam decorrer da presença de vírus ou de outros elementos nocivos na Plataforma.</div>
    <br />
    <div>A CONTRATADA poderá, sem anuência ou concordância do CONTRATANTE, realizar quaisquer alterações na Plataforma que julgar necessárias, sem que qualquer valor ou indenização seja devida ao mesmo em razão disso.</div>
    <br />
    <div>Na máxima extensão permitida pela legislação aplicável, o máximo valor a que a CONTRATADA se responsabiliza, independentemente do motivo que originou o pedido, está limitado ao montante eventualmente pago pelo aluno e CONTRATANTE desde que inequivocamente comprovado o prejuízo alegado.</div>
    <br />
    <div>Todos os depoimentos de CONTRATANTES divulgados pela CONTRATADA são verdadeiros, assim como os resultados apresentados. Porém, a CONTRATADA não se responsabiliza ou garante qualquer resultado de nenhum CONTRATANTE, visto que isso depende principalmente do seu comprometimento e esforço, atributos que estão fora do controle da CONTRATADA.</div>
    <br />
    <br />
    <div>FALE CONOSCO E DENUNCIE</div>
    <br />
    <div>Fique à vontade para entrar em contato conosco em caso de dúvidas sobre quaisquer das práticas relativas a informações nos Serviços e/ou sobre os Termos de Uso, a <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-privacidade'> Política de Privacidade </a>, e a <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-protecao-aos-direitos-autorais'> Política de Proteção aos Direitos Autorais </a>.</div>
    <br />
    <div>Em caso de dúvidas e/ou denúncias sobre a comercialização ou disponibilização de Cursos em outros locais que não na Plataforma da CONTRATADA, entre em contato conosco, através do email contato@leanit101.com.</div>
    <br />
    <br />
    <div>DA LEGISLAÇÃO E DO FORO</div>
    <br />
    <div>Os presentes Termos de Uso serão regidos, interpretados e executados de acordo com as leis da República Federativa do Brasil, independentemente dos conflitos dessas leis com leis de outros estados ou países, sendo competente o Foro de Palhoça SC, no Brasil, para dirimir qualquer dúvida decorrente deste instrumento. O CONTRATANTE consente, expressamente, com a competência desse juízo, e renúncia, neste ato, à competência de qualquer outro foro, por mais privilegiado que seja ou venha a ser.</div>
    <br />
    <br />
    <div>DAS DISPOSIÇÕES FINAIS, DA ATUALIZAÇÃO DESTES TERMOS DE USO E DA CONCORD NCIA DO CONTRATANTE</div>
    <br />
    <div>Mesmo que qualquer parte destes Termos de Uso seja considerada inválida ou inexequível, as demais disposições permanecerão em pleno vigor e efeito, sendo que o referido trecho deverá ser interpretado de forma consistente com a lei aplicável, para refletir, na medida do possível, a intenção original das partes.</div>
    <br />
    <div>Eventual falha da CONTRATADA em exigir quaisquer direitos ou disposições dos presentes Termos de Uso não constituirá renúncia, podendo exercer regularmente o seu direito, dentro dos prazos legais.</div>
    <br />
    <div>Qualquer problema relacionado ao site ou à privacidade e proteção de dados deve ser reportada à nossa equipe através do e-mail contato@leanit101.com e estaremos à disposição para atender e sanar todas as suas dúvidas.</div>
    <br />
    <div>Ao adquirir qualquer produto da CONTRATADA, o CONTRATANTE concorda em se vincular a todas as cláusulas do presente Termos De Uso, bem como a <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-privacidade'> Política de Privacidade </a> e a <a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-protecao-aos-direitos-autorais'> Política de Proteção aos Direitos Autorais </a> da CONTRATADA.</div>
    <br />
    <div>Bem-vindo à Lean it 101</div>
    <br />
    <div>Última atualização: 14.09.2022</div>
    <br />
  </div>
)

const TermsOfUse = () => {
  return (
    <div className=''>
      <Policy name='Termos de uso' text={termsOfUse} />
    </div>
  )
}

export default TermsOfUse
