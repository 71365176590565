import { useTranslation } from 'react-i18next'
import '../../input.css'
import CtaButton from './CtaButton'

const ScarcitySection = () => {
  const [t] = useTranslation()
  return (
    <div
      id='CoverPage'
      className='flex justify-between flex-col bg-gradient-radial4 to-steelGray via-leanit101Blue from-leanit101BlueLight4 pt-10  md:pt-28'
    >
      <div className='flex flex-col 2xl:flex-row w-full p-5'>
        <div className='h-full w-full md:px-40 flex flex-col items-center justify-center'>
          <span className='md:text-2xl text-xl font-regular'>
            {t('scarcity.title')}
            <span className='font-black px-2'>
              {t('scarcity.tempo')}
            </span>
            {t('scarcity.garantir')}
            <span className='font-black px-2'>
              {t('scarcity.agora')}
            </span>
          </span>
          <br />
          <span className='text-xl md:text-2xl mt-4 italic font-medium '>
            {t('scarcity.aproveite')}
          </span>
          <div className='my-16 '>
            <CtaButton />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScarcitySection
