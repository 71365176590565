import '../../../input.css'
import Testimonial from './Testimonial'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useTranslation } from 'react-i18next'

const TestimonialsPage = () => {
  const [t] = useTranslation()

  const testimonials2 = [
    {
      name: 'Carolina Isoppo',
      position: t('testimonial.position_1'),
      company: 'BlueTicket',
      quote: t('testimonial.testimonial_1'),
      imgUrl: 'images/Testimonials/carolina.png'
    },
    {
      name: 'Jeff Pagnutti',
      position: t('testimonial.position_2'),
      company: 'Vale',
      quote: t('testimonial.testimonial_2'),
      imgUrl: 'images/Testimonials/jeff.jpeg'
    },
    {
      name: 'Alberico Lima',
      position: t('testimonial.position_3'),
      company: 'Vale',
      quote: t('testimonial.testimonial_3'),
      imgUrl: 'images/Testimonials/alberico.jpg'
    },
    {
      name: 'Caio Monterazo',
      position: t('testimonial.position_4'),
      company: 'Vale',
      quote: t('testimonial.testimonial_4'),
      imgUrl: 'images/Testimonials/caio.jpeg'
    },
    {
      name: 'Juliana Chagas',
      position: t('testimonial.position_5'),
      company: 'Vale',
      quote: t('testimonial.testimonial_5'),
      imgUrl: 'images/Testimonials/juliana.jpeg'
    },
    {
      name: 'Victor Henrique',
      position: t('testimonial.position_6'),
      company: 'Vale',
      quote: t('testimonial.testimonial_6'),
      imgUrl: 'images/Testimonials/victor.jpeg'
    }
  ]
  const testimonials = [
    {
      name: 'Ian Stegmann',
      position: t('testimonial.position_7'),
      company: 'Vale',
      quote: t('testimonial.testimonial_7'),
      imgUrl: 'images/Testimonials/ian.jpeg'
    },
    {
      name: 'Marcelo Matzembacher',
      position: t('testimonial.position_8'),
      company: 'Resultados Digitais',
      quote: t('testimonial.testimonial_8'),
      imgUrl: 'images/Testimonials/marcelo.png'
    },
    {
      name: 'Diogo Kleinubing',
      position: t('testimonial.position_9'),
      company: 'BlueTicket',
      quote: t('testimonial.testimonial_9'),
      imgUrl: 'images/Testimonials/diogo.jpeg'
    },
    {
      name: 'Valcemar Cardoso',
      position: t('testimonial.position_10'),
      company: 'HBSIS',
      quote: t('testimonial.testimonial_10'),
      imgUrl: 'images/Testimonials/valcemar.jpeg'
    },
    {
      name: 'Philipe Fernandes',
      position: t('testimonial.position_11'),
      company: 'Vox Tecnologia',
      quote: t('testimonial.testimonial_11'),
      imgUrl: 'images/Testimonials/philipe.jpeg'
    },
    {
      name: 'Jorge Almoas',
      position: t('testimonial.position_12'),
      company: 'DigithoBrasil',
      quote: t('testimonial.testimonial_12'),
      imgUrl: 'images/Testimonials/jorge.jpeg'
    }
  ]

  return (
    <div
      id='TestimonialsPage'
      className='text-black h-full md:text-base md:px-48 bg-steelGray py-24 px-[0.25rem] flex-wrap overflow-auto'
    >
      <div className='flex flex-col lg:flex-row items-start justify-center'>
        <div className='w-full'>
          <div className='flex justify-start items-start text-center flex-col lg:w-1/2'>
            <span className='md:text-left md:text-6xl text-4xl font-semibold text-white text-wrap max-w-xl'>
              {t('testimonial.title')}

            </span>
            <span className='flex min-w-[100px] min-h-[100px] md:ml-0 ml-2'>
              <ArrowForwardIcon fontSize='' className='mt-2 md:rotate-90 rotate-90 md:ml-0 ml-4 text-5xl text-white' />
            </span>
          </div>
          {testimonials.map((element, id) => {
            return (
              <Testimonial key={`testimonial-${id}`} content={element} id={id} className='w-full' />
            )
          })}
        </div>
        <div className='w-full'>
          {testimonials2.map((element, id) => {
            return (
              <Testimonial key={`testimonial2-${id}`} content={element} id={id} className='w-full' />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default TestimonialsPage
