import './LandingPageAcess.scss'

const LandingPageAcess = () => {
  return (
    <div className='LandingPageAcess'>

      <div
        className='d-flex justify-content-center align-items-center' style={{
          backgroundImage: "url('/images/landing-lean-agile-foundation/acessLp.png')",
          backgroundRepeat: 'no-repeat',
          minHeight: 'max(41.6vw, 350px)',
          backgroundSize: 'cover',
          backgroundPosition: '20% center',
          opacity: '1'
        }}
      >
        <div className='container px-4 p-5'>

          <div className='row'>
            <div className='col-md-6 col-12 text-base-start justify-content-md-center d-flex flex-col p-2'>
              <h1 className='h1-responsive'>
                <span className='white'>
                  Acesse o treinamento
                </span> <br />
                <span className='blue'>
                  de onde quiser
                </span>
              </h1>

              <h3 className=' mt-5'>
                <span className='white'>
                  Você pode fazer o
                </span>
                <span className='orange'>
                  {' Lean e Agile Foundation no seu ritmo'}
                </span>
                <span className='white'>
                  , ver e rever as aulas quantas vezes desejar.
                </span> <br /> <br />
                <span className='white'>
                  O treinamento pode ser acessado via celular, tablet ou computador.
                </span>

              </h3>

            </div>
            <div className='col-md-6 col-12 p-5'>
              <img src='/images/landing-lean-agile-foundation/mockup.png' alt='' width='100%' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPageAcess
