import { useTranslation } from 'react-i18next'
import '../../input.css'

const LeanPyramidHistory = () => {
  const [t] = useTranslation()

  return (
    <>
      <div className=''>
        <div
          className='bg-vulcan w-full h-full lg:px-72 px-[1rem] py-24 bg-gradient-radial4 from-pyramid-19 to-steelGray h-fulrl text-cente'
          style={{
            backgroundImage: 'url(/images/background2.svg)',
            backgroundRepeat: 'no-repeat',
            height: '100%',
            backgroundSize: '100%',
            backgroundPosition: '50% 50%'
          }}
        >
          <div className='text-5xl md:font-black font-semibold md:text-left'>
            {t('pyramid_history.title')}

          </div>
          <div className='md:font-medium font-medium text-lg mt-5 md:text-left'>
            {t('pyramid_history.text_1')}

            <br /> <br />
            {t('pyramid_history.text_2')}

          </div>
        </div>
      </div>
    </>
  )
}

export default LeanPyramidHistory
