import { useEffect } from 'react'
import '../../input.css'
import i18next from 'i18next'

import { launchLpEvent, launchSubscribedEvent } from './FaceBookEventTracking'

const VirtueArmy = () => {
  i18next.changeLanguage('pt-BR')

  useEffect(() => {
    launchLpEvent()
  }, [])

  const sendUserToForm = async () => {
    launchSubscribedEvent()
    window.location.href = 'https://bit.ly/mentoria-para-mulheres'
  }

  return (
    <div
      id='CoverPage'
      className='flex md:h-screen h-full text-white flex-col bg-gradient-radial to-danielaDark via-danielaPink from-danielaLightPurple font-Quicksand'
    >
      <div className='bg-danielaDark h-16 w-full flex justify-center items-center'>
        <img src='images/virtuearmy/logo-dani-white.png' alt='Logo' className='h-2/3' />
      </div>

      <div className='flex md:flex-row flex-col  justify-between items-center py-4 '>

        <div className='flex flex-col 2xl:flex-row justify-around md:px-24 px-3 w-full items-center'>

          <div className=' mt-4 flex items-center flex-col'>
            <span className='md:text-5xl text-3xl text-start font-black md:max-w-[700px] drop-shadow-2xl'>
              MENTORIA PARA MULHERES
            </span>
            <div className='md:w-[700px] self-center flex p-4 drop-shadow-2xl'>
              <img src='images/virtuearmy/1.png' alt='' />
            </div>
          </div>

          <div className='flex flex-col text-left gap-3 md:w-[600px] h-fit bg-danielaDark drop-shadow-2xl border-1 border-solid border-pyramid-19 rounded-xl md:p-12 p-7'>
            <span className='text-3xl font-black'>
              SEJA UMA MULHER DIFERENCIADA
            </span>
            <span className='text-base '>
              Existe um caminho que foi desenhado para que toda mulher tenha plenitude em todas as áreas da vida à começar pelo lar. Vou te ensinar como equilibrar e priorizar as demandas e responsabilidades, e exercer sua real missão, seja como profissional, esposa, mãe, administradora do lar.
              <br />
              <br />

              Serão 12 encontros de 2 horas, cobrindo áreas que irão transformar a sua vida. Preparado especialmente para mulheres que desejam se diferenciar em meio a multidão.

              <br />
              <br />
              <ul className='font-semibold list-disc list-inside'>
                <li>Mulheres casadas ou solteiras</li>
                <li>Mulheres com ou sem filhos</li>
                <li>Mulheres de negócios</li>
                <li>Mulheres disposas a mudar</li>
              </ul>

              <br />
              Preencha o formulário para iniciar a sua aplicação para o nosso processo seletivo.
            </span>
            <div className='text-w flex justify-center'>

              <input
                type='button'
                className='text-white bg-danielaPurple hover:bg-danielaDarkLight hover:cursor-pointer font-black rounded-full w-96 mt-5 p-3 px-4 self-center'
                value='Quero Participar'
                onClick={() => sendUserToForm()}
              />
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default VirtueArmy
