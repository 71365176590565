import '../../../input.css'

const Testimonial = ({ content, id }) => {
  return (
    <div
      id={`testimonial-${id}`}
      className='p-3 w-full  drop-shadow-x'
    >
      <div className='pl-[5px] rounded-md bg-gradient-to-b from-pyramid-19 via-pyramid-1 to-pyramid-10 w-full'>
        <div className='bg-vulcan text-white w-full flex flex-col rounded-r-md justify-center items-start p-5 md:p-12 border-1 border-gray-800'>
          <div className='flex flex-row'>
            <img src={content.imgUrl} alt='' className='h-20 ws-20 rounded-full outline outline-offset-4 outline-cyan-600' />
            <div className='flex flex-col ml-5 text-left justify-center'>
              <span className='font-black text-xl md:text-2xl'>
                {content.name}
              </span>
              <span className='text-cyan-600 text-base md:text-xl'>
                {content.position}
              </span>
              <span className='text-gray-400 font-medium tracking-wider text-base md:text-xl'>
                {content.company}
              </span>
            </div>
          </div>
          <hr className='flex w-full h-[1px] border-none self-center mb-3 mt-4 bg-gradient-to-r from-pyramid-19 via-pyramid-1 to-pyramid-10' />
          <span className='text-blue-100 font-medium text-base md:text-left rounded-b-xl flex items-center'>
            {content.quote}
          </span>

        </div>
      </div>
    </div>
  )
}

export default Testimonial
