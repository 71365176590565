import { useTranslation } from 'react-i18next'
import '../../input.css'

const StickyCta = () => {
  const [t] = useTranslation()
  return (
    <div className='fixed bottom-0 w-full flex flex-col justify-center z-30'>

      <div className='h-[100px] w-full bg-gradient-to-t from-steelGray to-transparent ' />
      <div className='bg-steelGray flex justify-center flex-row items-center px-5 py-5 gap-5'>
        <span className='md:text-2xl text-sm  text-center py-1 font-semibold'>
          {t('sticky_cta.text_1')}
          <br />
          {t('sticky_cta.text_2')}
        </span>
        <button
          onClick={() => {
            window.location.href = 'https://pay.hotmart.com/I74074421T?off=i3wfklt8'
          }}
          id='academy-button'
          className='flex md:justify-start justify-center'
          target='_blank'
        >
          <span className='md:p-3 md:px-4  p-2 rounded-2xl md:text-2xl text-xl bg-orange-500 font-extrabold hover:bg-orange-400 drop-shadow-lg'>
            {t('sticky_cta.button')}
          </span>
        </button>
      </div>
    </div>
  )
}

export default StickyCta
