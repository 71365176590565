import React from 'react'
import { hydrate, render } from 'react-dom'
import './index.css'
import App from './App.js'
import reportWebVitals from './reportWebVitals.js'
const rootElement = document.getElementById('root')

if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement)
} else {
  render(<App />, rootElement)
}

reportWebVitals()
