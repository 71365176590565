import { useTranslation } from 'react-i18next'
import '../../input.css'

const BonusSection = () => {
  const [t] = useTranslation()
  const bonusContent = [
    {
      title: t('bonus.guide'),
      imgURL: 'images/ebookmockup.png'
    },
    {
      title: t('bonus.whystrategy'),
      imgURL: 'images/ebook.png'
    },
    {
      title: t('bonus.leanexplained'),
      imgURL: 'images/principiosLeanMockup.png'
    },
    {
      title: t('bonus.assessment'),
      imgURL: 'images/assessment.png'
    }
  ]

  const BonusItem = ({ title, imgURL }) => {
    return (
      <div className='md:min-w-[270px] py-auto bg-gradient-radial3 to-steelGray via-leanit101Blue from-leanit101BlueLight4 m-5 border-2 border-solid border-pyramid-19 rounded-xl p-5 flex flex-col items-center justify-start'>
        <div className='text-base p-3'>
          {title}
        </div>
        <img src={imgURL} alt='' className='p-3 my-auto' />
      </div>
    )
  }

  return (
    <div className='p-3 md:px-48 flex flex-col md:flex-row  w-full'>
      <div className='text-xl md:text-3xl font-black bg-steelGray rounded-xl flex flex-col md:p-5 md:gap-5 p-2 gap-2 w-full'>
        <span className='text-left p-3'>
          {t('bonus.title')}

        </span>
        <div className='rounded-xl flex flex-col  md:flex-row overflow-scroll border-2 border-solid border-neutral-800 bg-vulcan scroll no-scrollbar'>
          {bonusContent.map((item, id) => { return <BonusItem key={`bonusItem-${id}`} title={item.title} imgURL={item.imgURL} /> })}
        </div>
      </div>
    </div>
  )
}

export default BonusSection
