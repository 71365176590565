import { useTranslation } from 'react-i18next'
import '../../input.css'

const OfferSection = () => {
  const [t] = useTranslation()

  const valueItems = [
    {
      title: t('offer.item_1'),
      price: 'R$ 5.055,00'
    },
    {
      title: t('offer.item_2'),
      price: 'R$ 6.000,00'
    },
    {
      title: t('offer.item_3'),
      price: t('offer.item_3.1')
    },
    {
      title: t('offer.item_4'),
      price: 'R$ 1.500,00'
    },
    {
      title: t('offer.item_5'),
      price: 'R$ 3.000,00'
    }
  ]

  const bonusItems = [
    {
      title: t('offer.item_6'),
      price: 'R$ 360,00'
    },
    {
      title: t('offer.item_7'),
      price: 'R$ 97,00'
    },
    {
      title: t('offer.item_8'),
      price: 'R$ 197,00'
    },
    {
      title: t('offer.item_9'),
      price: 'R$ 397,00'
    },
    {
      title: t('offer.item_10'),
      price: 'R$ 97,00'
    },
    {
      title: t('offer.item_11'),
      price: 'R$ 297,00'
    },
    {
      title: t('offer.item_12'),
      price: 'R$ 297,00'
    }
  ]

  const renderBonusItems = (item) => {
    return (
      <div className='flex p-[1px] bg-gradient-to-r from-pyramid-19 via-pyramid-1 to-pyramid-10 rounded-3xl drop-shadow-xl'>
        <div className='bg-vulcan  rounded-3xl w-full flex md:p-6 md:px-12 p-6 md:text-3xl text-start gap-4  text-sm pl-7 justify-between items-center'>
          <span>
            {item.title}
          </span>
          <span className='text-white font-black bg-pyramid-10 p-3 rounded-xl min-w-fit'>
            {item.price}
          </span>
        </div>
      </div>
    )
  }

  const renderValueItems = (item) => {
    return (
      <div className='flex p-[1px] bg-pyramid-1  rounded-3xl drop-shadow-xl'>
        <div className='bg-vulcan  rounded-3xl w-full flex md:p-6 md:px-12 p-6 md:text-3xl text-start gap-4  text-sm pl-7 justify-between items-center'>
          <span>
            {item.title}
          </span>
          <span className='text-white font-black bg-pyramid-19 p-3 rounded-xl min-w-fit'>
            {item.price}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div
      id='OfferSection'
      className='bg-steelGray  md:px-48 px-[1rem]'
    >
      <div className='flex text-center py-16 px-6 justify-center'>
        <span className=' md:text-6xl text-4xl font-light text-white text-wrap'>
          {t('offer.title')}
        </span>
      </div>
      <div className='flex flex-col gap-2'>
        {valueItems.map((item) => renderValueItems(item))}
        <div className='flex text-center py-16 px-6 md:px-12 '>
          <span className=' md:text-5xl text-3xl font-semibold text-white text-wrap'>
            {t('offer.bonus')}
          </span>
        </div>
        {bonusItems.map((item) => renderBonusItems(item))}
        <div className='p-1'>
          <div className='w-full flex flex-col-reverse md:flex-row md:px-12 md:p-6 md:text-3xl gap-3 text-base justify-between items-center '>
            <div className='flex md:justify-start w-full justify-center py-3'>
              <span className='italic md:text-2xl text-sm font-regular'>
                {t('offer.preco')}
              </span>
            </div>
            <div className='flex flex-row w-full justify-between md:justify-end py-3'>
              <span className='mr-3 font-medium'>
                {t('offer.valor')}
              </span>
              <span className='text-cyan-500 line-through'>
                R$ 17.297,00
              </span>
            </div>
          </div>
          <div className='w-full py-3 flex md:px-12 md:text-6xl text-xl font-semibold justify-between items-center text-left'>
            <span>
              {t('offer.paga')}
            </span>
            <div className='flex flex-col '>
              <span className='text-white bg-pyramid-1 md:text-6xl text-xl md:p-5 p-2 md:rounded-3xl rounded-xl min-w-fit'>
                Em até 12x de R$ 299,11
              </span>
            </div>
          </div>
          <div className='w-full py-3 flex-row items-end text-right pr-1 md:pr-12'>
            <span className='md:text-2xl text-base text-pyramid-1'>
              1x de R$ 2.997.00
            </span>
          </div>
        </div>
        <div className='w-full md:mt-12 mt-6 flex md:px-12 px-6 md:text-6xl text-xl font-semibold justify-center items-center mb-8'>
          <span className=' text-base md:text-2xl font-regular'>
            {t('offer.ande')}
          </span>
        </div>
      </div>
    </div>
  )
}

export default OfferSection
