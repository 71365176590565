import { useTranslation } from 'react-i18next'
import '../../input.css'

const HotmartSection = () => {
  const [t] = useTranslation()
  return (
    <div className='p-3 md:px-40 w-full flex flex-col md:py-8 self-center'>
      <div className='text-xl md:text-3xl font-black m-12'>
        {t('hotmart.title')}
      </div>
      <div className='p-[1px] rounded-xl bg-gradient-to-r from-pyramid-19 via-pyramid-1 to-pyramid-10 w-full'>
        <div className='text-xl md:text-3xl min-w-fit font-black bg-steelGray rounded-xl flex flex-col md:p-5 md:gap-5 p-2 gap-2 w-full'>
          <div className='min-w-fit text-left p-3 flex gap-3 flex-col'>
            <div className='text-2xl '>
              {t('hotmart.conheca')}
            </div>
            <div className='text-base'>
              {t('hotmart.aparencia')}
            </div>
          </div>

          <div className='flex flex-auto justify-end w-full rounded-xl aspect-video'>

            <iframe
              style={{
                border: '1px solid rgba(11, 166, 218, 0.6)'
              }}
              title='hotmart'
              width='100%'
              className='rounded-2xl drop-shadow-xl aspect-video w-full'
              src='https://www.youtube.com/embed/1-BfbBH8W7w?autoplay=1&amp;mute=1&amp;loop=1&amp;controls=0'
              frameborder='0'
              allow=' autoplay; clipboard-write; encrypted-media; gyroscope'
            />

            {/* <div className='rounded-2xl drop-shadow-xl aspect-video w-full'>

              <video
                autoPlay
                loop
                muted
              >
                <source
                  src='images/AreaDeMembrosHotmart/areademembros1.mp4'
                  type='video/mp4'
                />
              </video>
            </div> */}

          </div>
        </div>
      </div>
    </div>
  )
}

export default HotmartSection
