import '../../input.css'

const AcademyNetflixSection = () => {
  return (
    <>
      <div
        id='AcademyNetflixSection'
        className='bg-ebony flex flex-col'
      >
        <div className='w-screen flex justify-center mt-20'>
          <img src='images/netflixsection.png' alt='' className='p-[1px] rounded-xl bg-gradient-to-r from-pyramid-19 via-pyramid-1 to-pyramid-10 w-[1024]' />
        </div>

      </div>
    </>
  )
}

export default AcademyNetflixSection
