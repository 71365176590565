import './LandingPageTeacher.scss'

const LandingPageTeacher = () => {
  return (
    <div className='LandingPageTeacher'>

      <div
        className=''
        style={{
          backgroundImage: "url('/images/landing-lean-agile-foundation/noNoiseTeacherLp.png')",
          backgroundRepeat: 'no-repeat',
          minHeight: 'fit-content',
          backgroundSize: 'cover',
          backgroundPosition: '52% 20%',
          opacity: '1'
        }}
      >
        <div className='body-content p-md-5 pt-5 p-3 '>
          <div className=''>

            <div className='row'>
              <div className='col-md-6 col-8 d-flex flex-column text start'>

                <h1 className='display-5 white'>
                  Quem será seu professor?
                </h1>
                <h1 className='h5 blue'>
                  Samuel Crescêncio
                </h1>

                <span className='body-text text-start white py-md-5 py-3'>

                  Engenheiro de software, autor, instrutor, empreendedor e experiente executivo, com mais de 25 anos de experiência prática full-stack.
                  <br /> <br />
                  Conheceu os métodos ágeis em 2003 e foi um dos membros do conselho de administração da Agile Alliance,
                  <span className='blue'>
                    {' ajudando a expandir as metodologias ágeis ao redor do mundo.'}
                  </span>

                  <br /> <br />
                  Criou o Framework de transformação Pirâmide Lean, responsável por otimizar processos fundamentais de uma organização, desde o planejamento estratégico até a engenharia de software e chão de fábrica.
                  <br /> <br />
                  Desde então,
                  <span className='white'>
                    {' está promovendo a transformação em grandes organizações '}
                  </span>

                  como por exemplo no ramo de tecnologia, energia, mineração e financeiro.

                </span>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPageTeacher
