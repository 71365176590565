import { useState } from 'react'
import '../../../input.css'
import Class from './Class'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
// import LaunchIcon from '@mui/icons-material/Launch'
import { useTranslation } from 'react-i18next'

const Module = ({ module, id }) => {
  const [classOpened, setClassOpened] = useState('')
  const [t] = useTranslation()

  const handleSetClassOpened = (classId) => {
    if (classId === classOpened) {
      setClassOpened('')
    } else {
      setClassOpened(classId)
    }
  }
  console.log(`modules.${id}.title`)
  return (

    <div
      id={id}
      className='flex md:flex-row flex-col bg-steelGray md:p-10 p-[1rem] md:gap-5 rounded-lg border-1 border-solid border-gray-700 m-3 transition-all drop-shadow-lg'
    >
      <div className='flex flex-col md:w-1/2 w-full text-center '>
        <div
          className='p-[1px] rounded-lg drop-shadow-xl  bg-gradient-to-r from-pyramid-19 via-pyramid-10 to-pyramid-1'
        >
          <img src={module.image} alt='' className='rounded-lg drop-shadow-lg' />
        </div>
        <span className='md:text-4xl text-2xl font-black text-center inline md:hidden pt-3'>
          {t(`modules.${id}.title`)}
        </span>
        <span className='p-4 md:text-lg text-base md:text-left'>
          {t(`modules.${id}.description`)}          <br /> <br />
          <span className='font-bold text-lg text-left w-full flex mt-1'>
            {t('modules.beneficios')} <br /> <br />
          </span>
          <span className='text-left'>
            {module.benefits.map((benefit, benefitId) => {
              return (
                <li
                  key={`modules.${id}.benefit_${benefitId + 1}`}
                  id={`modules.${id}.benefit_${benefitId + 1}`}
                >
                  {t(`modules.${id}.benefit_${benefitId + 1}`)}
                </li>
              )
            })}
          </span>
        </span>
      </div>
      <div className='flex flex-col md:w-1/2'>
        <div className='flex md:flex-col  flex-col justify-between text-left'>
          <span className='md:text-4xl text-2xl font-black text-left hidden md:inline'>
            {/* {module.title} */}
            {t(`modules.${id}.title`)}

          </span>
          <div className=' md:text-xl text-cyan-500 flex md:flex-row flex-col-reverse md:items-start md:h-full md:justify-between items-center md:gap-0 gap-2 '>
            <span className='text-left text-xl m-2 font-regular md:self-end self-start text-slate-400'>{t('modules.aulas')}</span>
            <div className='flex flex-col md:flex-row items-center justify-end h-full md:p-3 pb-3 gap-2 w-full'>
              <div className='flex-row flex text-lg'>
                <div className='flex items-center justify-center p-2'>
                  <CheckCircleIcon fontSize='' className='md:text-3xl' />
                  <span className='pl-2 '>
                    {module.duration}
                  </span>
                </div>
                <div className='flex items-center justify-center p-2'>
                  <CheckCircleIcon fontSize='' className='md:text-3xl' />
                  <span className='pl-2'>
                    {module.numberOfClasses}
                  </span>
                </div>
              </div>
              {/* <button
                onClick={(e) => {
                  e.preventDefault()
                  window.location.href = module.link
                }} target='_blank' className='rounded-full text-white bg-cyan-600 transition-all hover:bg-cyan-500 p-4 font-semibold text-xl  w-full md:w-max flex items-center justify-center'
              >
                {t('modules.saiba')}
                <LaunchIcon fontSize='' className='text-xl ml-1 h-full' />
              </button> */}
            </div>
          </div>
        </div>
        <div className='flex flex-col bg-steelGray h-full w-full  text-center '>
          <span className='font-regular sm:text-left text-slate-200 transition'>
            {module.classes.map((course, courseId) => {
              return (
                <Class
                  key={`modules.${id}.classes.class_${courseId + 1}`}
                  id={`modules.${id}.classes.class_${courseId + 1}`}
                  moduleId={id}
                  course={course}
                  courseId={courseId}
                  setClassOpened={handleSetClassOpened}
                  openClass={classOpened}
                />
              )
            })}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Module
