import '../../input.css'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useTranslation } from 'react-i18next'

const renderBenefits = (benefit, id) => {
  return (
    <div className='md:text-2xl text-lg flex items-center py-2' key={id}>
      <span className='text-4xl flex items-center'>
        <CheckCircleOutlineIcon fontSize='' className='text-pyramid-1 mr-3' />
      </span>
      {benefit}
    </div>
  )
}

const renderProblems = (problem, id) => {
  return (
    <div className='md:text-2xl text-lg flex items-center py-2' key={id}>
      <span className='text-4xl flex items-center'>
        <HighlightOffIcon fontSize='' className='text-pyramid-10 mr-3 ' />
      </span>
      {problem}
    </div>
  )
}
const LeanPyramidBenefitsSection = () => {
  const [t] = useTranslation()

  const benefits = [
    t('benefits.benefit_1'),
    t('benefits.benefit_2'),
    t('benefits.benefit_3'),
    t('benefits.benefit_4'),
    t('benefits.benefit_5'),
    t('benefits.benefit_6'),
    t('benefits.benefit_7'),
    t('benefits.benefit_8'),
    t('benefits.benefit_9')
  ]

  const problems = [
    t('benefits.problem_1'),
    t('benefits.problem_2'),
    t('benefits.problem_3'),
    t('benefits.problem_4'),
    t('benefits.problem_5'),
    t('benefits.problem_6'),
    t('benefits.problem_7'),
    t('benefits.problem_8'),
    t('benefits.problem_9')
  ]

  return (
    <div className='p-[1rem] md:px-48 flex flex-col  bg-steelGray py-12 md:py-16'>
      <span className='md:text-5xl text-3xl font-extrabold py-6'>
        {t('benefits.title')}
      </span>
      <div className='flex md:flex-row flex-col-reverse w-full text-left justify-between text-3xl md:px-12'>
        <div className='flex flex-col w-full'>
          <span className='md:text-3xl text-xl font-semibold py-12'>
            {t('benefits.sem')}
          </span>
          {problems.map(renderProblems)}
        </div>
        <div className='flex flex-col w-full'>
          <span className='md:text-3xl text-xl font-semibold py-12'>
            {t('benefits.com')}
          </span>
          {benefits.map(renderBenefits)}
        </div>
      </div>
    </div>
  )
}

export default LeanPyramidBenefitsSection
