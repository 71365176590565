import '../../input.css'
import Policy from './Policy'

const authorsRights = (
  <div>
    <div>O presente instrumento é fornecido pela LEANIT101 SERVIÇOS EM INFORMÁTICA, inscrita no CNPJ sob o número 22.724.260/0001-42, com sede na Rua Capri, nº 465, apto 302, Passa Vinte, Palhoça/SC - CEP 88132-229, contato eletrônico: contato@leanit101.com, denominada como CONTRATADA, com a finalidade de orientar “Você”, na qualidade de “Aluno”, “Usuário” ou “Visitante”, denominado como CONTRATANTE, a respeito do estabelecimento da presente Política de Proteção aos Direitos Autorais, nos termos a seguir:</div>
    <br />
    <br />
    <div>DAS DEFINIÇÕES</div>
    <br />
    <div>No intuito de orientar a interpretação dos termos do presente contrato, deve-se considerar as seguintes definições:</div>
    <br />
    <div>“Aluno”: trata-se do Usuário que está realizando o(s) treinamentos(s);</div>
    <br />
    <div>“Conteúdo”: todo material disponibilizado na Plataforma, tais como videoaulas, materiais de apoio, textos, códigos, avaliações, atividades, arquivos, modelos para avaliação da maturidade, templates, dentre outros;</div>
    <br />
    <div>“Plataforma”: Site, comunidade e qualquer ambiente virtual de Aprendizagem ou de Avaliação (assessment) da Lean it 101;</div>
    <br />
    <div>“<a className='underline text-slate-400 hover:text-slate-300' href='/politica-de-privacidade'> Política de Privacidade </a>”: regras estatuídas pela Lean it 101 para proteção da privacidade do Usuário, bem como para trazer transparência acerca do tratamento dos dados e informações pessoais que são disponibilizados na Plataforma;</div>
    <br />
    <div>“Política de Proteção aos Direitos Autorais”: regras estatuídas pela Lean it 101 para orientar “Você” sobre a proteção à propriedade intelectual conferida pela Lei de Direitos Autorais, pela Constituição Federal, e demais legislações aplicáveis;</div>
    <br />
    <div>“Usuário”: pessoa física, maior de 16 (dezesseis) anos, ou jurídica, por seu representante, que se cadastra na Plataforma e dela faz uso, a partir de um login e senha;</div>
    <br />
    <div>“Você”: pessoa física ou jurídica que acessa, se cadastra, adquire cursos e/ou faz uso, de um modo geral, da Plataforma, na qualidade de “Aluno”, “Usuário” e/ou “Visitante”.</div>
    <br />
    <br />
    <div>DAS RESSALVAS DOS<a className='underline text-slate-400 hover:text-slate-300' href='/termos-de-uso'> TERMOS DE USO</a> DAS PRERROGATIVAS DA CONTRATADA</div>
    <br />
    <div>Conforme disposto nos <a className='underline text-slate-400 hover:text-slate-300' href='/termos-de-uso'> Termos De Uso </a> da CONTRATADA, vale ressaltar os seguintes pontos:</div>
    <br />
    <ul className='list-decimal list-inside pl-10'>
      <li> As videoaulas só podem ser veiculadas, adquiridas e assistidas na Plataforma, disponível nos sites ou comunidades da CONTRATADA, existindo ainda, formas de monitoramento e segurança de acessos;</li>
      <li> A CONTRATADA possui exclusividade na gravação, disponibilização, comercialização e negociação do seu Conteúdo, ou seja, de todas videoaulas e demais matérias de apoio dos Cursos constantes em seus sites ou comunidades;</li>
      <li> É proibida a cópia, reprodução, armazenamento, rateamento, cessão e transmissão de qualquer Conteúdo da CONTRATADA pelo CONTRATANTE, Você, Aluno, Usuário, Visitante e/ou terceiros;</li>
      <li> Os Conteúdos da CONTRATADA não podem ser repassados, copiados, rateados, reproduzidos, distribuídos, transmitidos, difundidos, exibidos, vendidos, licenciados, adaptados ou, de outro modo, explorados para quaisquer fins, sem o consentimento prévio e por escrito da CONTRATADA;</li>
      <li> Fica desde já ciente o CONTRATANTE que o acesso a plataforma da CONTRATADA ocorrerá de forma única e exclusiva pelo próprio CONTRATANTE, não podendo o mesmo ser repassado e/ou disponibilizado para terceiros em nenhuma hipótese, sob pena de incorrer nas sanções legais cabíveis.</li>
    </ul>
    <br />
    <br />
    <div>DO INFORMATIVO</div>
    <br />
    <div>A CONTRATADA informa que o meio oficial para aquisição de seus cursos é o site https://leanit101.com. Todo o Conteúdo e cursos que são disponibilizados pela mesma são protegidos pela Lei de Direitos Autorais, pela Constituição Federal, e demais legislações aplicáveis. Assim, os Conteúdos e cursos vendidos, comprados ou adquiridos fora da plataforma oficial da CONTRATADA são provenientes da prática de pirataria e, portanto, sujeitam os responsáveis às medidas cíveis e criminais aplicáveis, bem como às respectivas consequências.</div>
    <br />
    <div>Adquira os produtos e Conteúdos originais da CONTRATADA, pois pirataria é crime.</div>
    <br />
    <div>A CONTRATADA possui sistemas e formas de monitoramento e segurança para identificar a venda e compra de seus Conteúdos por sites, pessoas e CONTRATANTES não autorizados.</div>
    <br />
    <div>Em caso de dúvidas e/ou denúncias sobre a comercialização ou disponibilização de Cursos em outros locais que não na Plataforma da CONTRATADA, entre em contato conosco, através do email contato@leanit101.com.</div>
    <br />
    <div>Caso o CONTRATANTE tenha uma denúncia sobre pirataria contra a CONTRATADA, a mesma pode ser realizada através de uma mensagem através do e-mail contato@leanit101.com, onde também poderá fornecer as evidências da comercialização, compartilhamento, uso ou qualquer tipo de exploração irregular de qualquer Conteúdo da CONTRATADA de forma segura e sigilosa.</div>
    <br />
    <br />
    <div>DAS DISPOSIÇÕES DA POLÍTICA DE PROTEÇÃO AOS DIREITOS AUTORAIS DA CONTRATADA</div>
    <br />
    <div>Os direitos sobre o Conteúdo contido na Plataforma, comunidade ou sites da CONTRATADA pertencem exclusivamente apenas a mesma, os quais despenderam e ainda dependem de grandes investimentos em infraestrutura tecnológica e na contratação de instrutores e profissionais de elevada qualificação técnica e científica.</div>
    <br />
    <div>O Conteúdo disponível na Plataforma, comunidade ou sites da CONTRATADA consiste em material protegido pela legislação brasileira, sendo certo que, a CONTRATADA por ser a detentora dos direitos sobre estes Conteúdos, possui direito exclusivo de usar, fruir e dispor de sua obra, conforme artigo 5º, inciso XXVII, da Constituição Federal e os artigos 7º e 28, da Lei 9.610/98.</div>
    <br />
    <div>A divulgação, veiculação e/ou venda do Conteúdo em sites que não a Plataforma, comunidade ou sites da CONTRATADA e sem a devida autorização da mesma, pode configurar violação de direito autoral, nos termos da Lei 9.610/98, inclusive podendo caracterizar conduta criminosa, prevista no artigo 184, §1º a 3º, do Código Penal (Lei n. 2.848/40), como crime de violação de direito autoral. Segue:</div>
    <br />
    <br />
    <div>Violação de direito autoral</div>
    <br />
    <div>Art. 184. Violar direitos de autor e os que lhe são conexos:</div>
    <br />
    <div>Pena – detenção, de 3 (três) meses a 1 (um) ano, ou multa</div>
    <br />
    <div>§ 1o Se a violação consistir em reprodução total ou parcial, com intuito de lucro direto ou indireto, por qualquer meio ou processo, de obra intelectual, interpretação, execução ou fonograma, sem autorização expressa do autor, do artista intérprete ou executante, do produtor, conforme o caso, ou de quem os represente: Pena – reclusão, de 2 (dois) a 4 (quatro) anos, e multa.</div>
    <br />
    <div>§ 2o Na mesma pena do § 1o incorre quem, com o intuito de lucro direto ou indireto, distribui, vende, expõe à venda, aluga, introduz no País, adquire, oculta, tem em depósito, original ou cópia de obra intelectual ou fonograma reproduzido com violação do direito de autor, do direito de artista intérprete ou executante ou do direito do produtor de fonograma, ou, ainda, aluga original ou cópia de obra intelectual ou fonograma, sem a expressa autorização dos titulares dos direitos ou de quem os represente.</div>
    <br />
    <div>§ 3o Se a violação consistir no oferecimento ao público, mediante cabo, fibra ótica, satélite, ondas ou qualquer outro sistema que permita ao usuário realizar a seleção da obra ou produção para recebê-la em um tempo e lugar previamente determinados por quem formula a demanda, com intuito de lucro, direto ou indireto, sem autorização expressa, conforme o caso, do autor, do artista intérprete ou executante, do produtor de fonograma, ou de quem os represente: Pena – reclusão, de 2 (dois) a 4 (quatro) anos, e multa.</div>
    <br />
    <div>É caracterizada como contrafação toda e qualquer reprodução e venda não autorizada, integral ou parcial, de todo e qualquer Conteúdo disponibilizado pela CONTRATADA.</div>
    <br />
    <div>A CONTRATADA ao verificar que teve o Conteúdo disponível em sua Plataforma fraudulentamente reproduzido, divulgado, utilizado, comercializado, rateado ou adquirido, poderá requerer a suspensão da divulgação pelo responsável, sem prejuízo de poder encerrar todos os acessos do CONTRATANTE ao seu Conteúdo, além de tomar a adoção das medidas inibitórias, indenizatórias e repressivas, no âmbito cível e criminal.</div>
    <br />
    <div>Caso o Conteúdo disponibilizado pela CONTRATADA seja indevidamente reproduzido e/ou utilizado em outro site, caberá ao responsável pela contrafação e/ou plágio indenizar a mesma e responder integralmente pelos seus atos.</div>
    <br />
    <div>Não havendo forma de demonstrar e comprovar o prejuízo causado com a divulgação do material, o cálculo da indenização material será feita com base no parágrafo único, do artigo 103, da Lei 9.610/98, o qual dispõe que, nesta hipótese, o transgressor deverá pagar o valor de 3.000 (três mil) cópias do conteúdo:</div>
    <br />
    <div>Art. 103. Quem editar obra literária, artística ou científica, sem autorização do titular, perderá para este os exemplares que se apreenderem e pagar-lhe-á o preço dos que tiver vendido.</div>
    <br />
    <div>Parágrafo único. Não se conhecendo o número de exemplares que constituem a edição fraudulenta, pagará o transgressor o valor de três mil exemplares, além dos apreendidos.</div>
    <br />
    <div>Aquele que adquirir, distribuir, ocultar, expuser, vender ou tiver em depósito Conteúdo da CONTRATADA reproduzido com fraude, também poderá responder por conduta criminosa, nesse caso, a prevista no artigo 180, §1º a 4º, do Código Penal (Lei n. 2.848/40), ao caracteriza o crime de receptação. Segue:</div>
    <br />
    <br />
    <div>Receptação</div>
    <br />
    <div>Art. 180 - Adquirir, receber, transportar, conduzir ou ocultar, em proveito próprio ou alheio, coisa que sabe ser produto de crime, ou influir para que terceiro, de boa-fé, a adquira, receba ou oculte: Pena - reclusão, de um a quatro anos, e multa.</div>
    <br />
    <br />
    <div>Receptação qualificada</div>
    <br />
    <div>§ 1º - Adquirir, receber, transportar, conduzir, ocultar, ter em depósito, desmontar, montar, remontar, vender, expor à venda, ou de qualquer forma utilizar, em proveito próprio ou alheio, no exercício de atividade comercial ou industrial, coisa que deve saber ser produto de crime: Pena - reclusão, de três a oito anos, e multa.</div>
    <br />
    <div>§ 2º - Equipara-se à atividade comercial, para efeito do parágrafo anterior, qualquer forma de comércio irregular ou clandestino, inclusive o exercício em residência.</div>
    <br />
    <div>§ 3º - Adquirir ou receber coisa que, por sua natureza ou pela desproporção entre o valor e o preço, ou pela condição de quem a oferece, deve presumir-se obtida por meio criminoso:</div>
    <br />
    <div>Pena - detenção, de um mês a um ano, ou multa, ou ambas as penas.</div>
    <br />
    <div>§ 4º - A receptação é punível, ainda que desconhecido ou isento de pena o autor do crime de que proveio a coisa.</div>
    <br />
    <div>Aquele que adquirir, distribuir, ocultar, expuser, vender ou tiver em depósito Conteúdo da CONTRATADA reproduzido com fraude, com a finalidade de vender, obter ganho, vantagem, lucro direto, para si ou para outrem, além das medidas criminais, poderá ser solidariamente responsável com o contrafator.</div>
    <br />
    <br />
    <div>DAS DISPOSIÇÕES FINAIS</div>
    <br />
    <div>O uso indevido do Conteúdo da CONTRATADA será regido e interpretado pelas leis da República Federativa do Brasil, independentemente dos conflitos dessas leis com leis de outros estados ou países, sendo competente o Foro da Comarca de Palhoça/SC, no Brasil, para dirimir qualquer dúvida decorrente deste instrumento. O CONTRATANTE consente, expressamente, com a competência desse juízo, e renuncia, neste ato, à competência de qualquer outro foro, por mais privilegiado que seja ou venha a ser.</div>
    <br />
    <div>Eventual falha da CONTRATADA em exigir quaisquer direitos não constituirá renúncia, podendo exercer regularmente o seu direito, dentro dos prazos legais.</div>
    <br />
    <div>Ao adquirir qualquer produto da CONTRATADA, o CONTRATANTE concorda em se vincular a todas as cláusulas da presente Política de Proteção aos Direitos Autorais, bem como dos <a className='underline text-slate-400 hover:text-slate-300' href='/termos-de-uso'> Termos De Uso </a> e da Política de Proteção aos Direitos Autorais da CONTRATADA.</div>
    <br />
    <div>Bem-vindo à Lean it 101</div>
    <br />
    <div>Última atualização: 14.09.2022</div>
    <br />
  </div>
)

const AuthorsRights = () => {
  return (
    <div className=''>
      <Policy name='Politica de proteção aos direitos autorais' text={authorsRights} />
    </div>
  )
}

export default AuthorsRights
