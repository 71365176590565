import './LandingPageLifes.scss'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
const LandingPageLifes = () => {
  return (
    <div className='LandingPageLifes '>

      <div
        className='' style={{
          backgroundImage: "url('/images/landing-lean-agile-foundation/noNoiseLifesLp.png')",
          backgroundRepeat: 'no-repeat',
          minHeight: 'max(41.6vw, 350px)',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          opacity: '1'
        }}
      >
        <div className='px-md-4 mt-2 p-4'>
          <div className='row'>
            <div className='lifes-title blue'>
              + DE 3000 VIDAS TRANSFORMADAS
            </div>
            <h3 className='white'>
              O QUE ESTÃO FALANDO DA GENTE:
            </h3>
          </div>
          <div className='row'>
            <div id='carousel' className='carousel slide' data-bs-ride='carousel'>

              <div className='carousel-indicators'>
                <button
                  type='button'
                  data-bs-target='#carousel'
                  className='carousel-indicator active'
                  data-bs-slide-to='0'
                  aria-current='true'
                  aria-label='Slide 1'
                />
                <button
                  type='button'
                  data-bs-target='#carousel'
                  className='carousel-indicator'
                  data-bs-slide-to='1'
                  aria-label='Slide 2'
                />
                <button
                  type='button'
                  data-bs-target='#carousel'
                  className='carousel-indicator'
                  data-bs-slide-to='2'
                  aria-label='Slide 3'
                />
                <button
                  type='button'
                  data-bs-target='#carousel'
                  className='carousel-indicator'
                  data-bs-slide-to='3'
                  aria-label='Slide 4'
                />
                <button
                  type='button'
                  data-bs-target='#carousel'
                  className='carousel-indicator'
                  data-bs-slide-to='4'
                  aria-label='Slide 5'
                />
                <button
                  type='button'
                  data-bs-target='#carousel'
                  className='carousel-indicator'
                  data-bs-slide-to='5'
                  aria-label='Slide 6'
                />
                <button
                  type='button'
                  data-bs-target='#carousel'
                  className='carousel-indicator'
                  data-bs-slide-to='6'
                  aria-label='Slide 7'
                />
                <button
                  type='button'
                  data-bs-target='#carousel'
                  className='carousel-indicator'
                  data-bs-slide-to='7'
                  aria-label='Slide 8'
                />
                <button
                  type='button'
                  data-bs-target='#carousel'
                  className='carousel-indicator'
                  data-bs-slide-to='8'
                  aria-label='Slide 9'
                />
              </div>

              <div className='carousel-inner pt-5 pt-md-0'>

                <div className='carousel-item active'>
                  <div className='d-flex flex-md-row flex-col text-center white item d-flex justify-content-md-center align-items-center'>
                    <div className='col-12 col-md-4 p-3'>
                      <h1 className='p-2'>
                        Carolina Isoppo
                      </h1>
                      <h4>
                        Gerente de Gestão de Pessoas
                      </h4>
                      <h5 className='blue'>
                        BlueTicket
                      </h5>
                    </div>
                    <div className='col-12 col-md-4 p-3'>
                      <h4 className=''>
                        "O workshop além de nos proporcionar novos aprendizados sobre metodologias ágeis, nos propiciou vários insights sobre os nossos processos e momentos muito bacanas de interação, e principalmente nos mobilizou para a mudança."
                      </h4>
                    </div>
                  </div>
                </div>

                <div className='carousel-item'>
                  <div className='d-flex flex-md-row flex-col text-center white item d-flex justify-content-md-center align-items-center'>
                    <div className='col-12 col-md-4 p-3'>
                      <h1 className=' p-2'>
                        Leonardo Hampel
                      </h1>
                      <h4>
                        Scrum Master
                      </h4>
                      <h5 className='blue'>
                        NDD
                      </h5>
                    </div>
                    <div className='col-12 col-md-4 p-3'>
                      <h4 className=''>
                        "Fantástico. Exemplos claros, profissional com bastante experiência. Simples. Objetivo."
                      </h4>
                    </div>
                  </div>
                </div>

                <div className='carousel-item'>
                  <div className='d-flex flex-md-row flex-col text-center white item d-flex justify-content-md-center align-items-center'>
                    <div className='col-12 col-md-4 p-3'>
                      <h1 className=' p-2'>
                        Diogo Kleinubing
                      </h1>
                      <h4>
                        CEO
                      </h4>
                      <h5 className='blue'>
                        BlueTicket
                      </h5>
                    </div>
                    <div className='col-12 col-md-4 p-3'>
                      <h4 className=''>
                        "Os exemplos práticos foram bastante interessantes para TI e para os outros setores. Com ele todos conseguiram ver como o lean é uma alternativa capaz de gerar impactos muito positivos no andamento dos projetos e caminhos práticos para começarem a mudança."
                      </h4>
                    </div>
                  </div>
                </div>

                <div className='carousel-item'>
                  <div className='d-flex flex-md-row flex-col text-center white item d-flex justify-content-md-center align-items-center'>
                    <div className='col-12 col-md-4 p-3'>
                      <h1 className=' p-2'>
                        Valcemar Cardoso
                      </h1>
                      <h4>
                        Diretor de Operações
                      </h4>
                      <h5 className='blue'>
                        HBSIS
                      </h5>
                    </div>
                    <div className='col-12 col-md-4 p-3'>
                      <h4 className=''>
                        "Método convergente com as necessidades de mercado. Foco em resultados. Toda a dinâmica do workshop permite profundas reflexões sobre a realidade da organização e a orientação para o processo de implementação da mudança. "
                      </h4>
                    </div>
                  </div>
                </div>

                <div className='carousel-item'>
                  <div className='d-flex flex-md-row flex-col text-center white item d-flex justify-content-md-center align-items-center'>
                    <div className='col-12 col-md-4 p-3'>
                      <h1 className=' p-2'>
                        Philipe Fernandes
                      </h1>
                      <h4>
                        Arquiteto de Software
                      </h4>
                      <h5 className='blue'>
                        Vox Tecnologia
                      </h5>
                    </div>
                    <div className='col-12 col-md-4 p-3'>
                      <h4 className=''>
                        "Em poucas palavras SHOW!!! Mostra de uma ponta a outra, os erros de um processo e como podemos ajustá-lo! Literalmente, fantástico!"
                      </h4>
                    </div>
                  </div>
                </div>

                <div className='carousel-item'>
                  <div className='d-flex flex-md-row flex-col text-center white item d-flex justify-content-md-center align-items-center'>
                    <div className='col-12 col-md-4 p-3'>
                      <h1 className=' p-2'>
                        Danilo Luiz
                      </h1>
                      <h4>
                        Diretor de Sistemas de Informação
                      </h4>
                      <h5 className='blue'>
                        Prefeitura Municipal de Garopaba

                      </h5>
                    </div>
                    <div className='col-12 col-md-4 p-3'>
                      <h4 className=''>
                        "Esse curso é para aqueles que aceitam que existem mudanças que devem ser feitas em seus processos como um todo. Ao fazer o Workshop da Pirâmide Lean o participante vai perceber que desempenha menos do que poderia e se esse deseja melhorar, vai precisar aceitar um grande desafio da mudança contínua e busca por excelência."
                      </h4>
                    </div>
                  </div>
                </div>

                <div className='carousel-item'>
                  <div className='d-flex flex-md-row flex-col text-center white item d-flex justify-content-md-center align-items-center'>
                    <div className='col-12 col-md-4 p-3'>
                      <h1 className=' p-2'>
                        Jorge Almoas
                      </h1>
                      <h4>
                        Analista de Marketing
                      </h4>
                      <h5 className='blue'>
                        DígithoBrasil
                      </h5>
                    </div>
                    <div className='col-12 col-md-4 p-3'>
                      <h4 className=''>
                        "O workshop é muito inspirador e enriquecedor. O Samuel consegue dividir o que sabe e o que já aplicou com generosidade e vontade de fazer com que também nos tornemos agilistas e evangelistas da agilidade. Recomendo muito!"
                      </h4>
                    </div>
                  </div>
                </div>

                <div className='carousel-item'>
                  <div className='d-flex flex-md-row flex-col text-center white item d-flex justify-content-md-center align-items-center'>
                    <div className='col-12 col-md-4 p-3'>
                      <h1 className=' p-2'>
                        Douglas Araujo Vieira
                      </h1>
                      <h4>
                        Desenvolvedor
                      </h4>
                      <h5 className='blue'>
                        Softplan
                      </h5>
                    </div>
                    <div className='col-12 col-md-4 p-3'>
                      <h4 className=''>
                        "A metodologia lean agregou demais, ajudou a planejar melhor as atividades, aproximou as equipes e mostrou uma outra visão de solução/entrega para o cliente, o que de fato é valor! Acredito que é fundamental difundir o Lean em outras empresas/equipes e operações."
                      </h4>
                    </div>
                  </div>
                </div>

                <div className='carousel-item'>
                  <div className='d-flex flex-md-row flex-col text-center white item d-flex justify-content-md-center align-items-center'>
                    <div className='col-12 col-md-4 p-3'>
                      <h1 className=' p-2'>
                        Marcelo Matzembacher
                      </h1>
                      <h4 className='fw-bold text'>
                        Implementation Success Manager
                      </h4>
                      <h5 className='blue'>
                        Resultados Digitais
                      </h5>
                    </div>
                    <div className='col-12 col-md-4 p-3'>
                      <h4 className=''>
                        "Eu só posso dizer MUITO OBRIGADO pelos ensinamentos do dia de hoje. Foi uma experiência revolucionária na minha vida pessoal e profissional."
                      </h4>
                    </div>
                  </div>
                </div>

              </div>

              <button className='carousel-control-prev' type='button' data-bs-target='#carousel' data-bs-slide='prev'>
                <ArrowBackIosIcon />
                <div className='visually-hidden'>Previous</div>
              </button>
              <button className='carousel-control-next' type='button' data-bs-target='#carousel' data-bs-slide='next'>
                <ArrowForwardIosIcon />
                <div className='visually-hidden'>Next</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPageLifes
