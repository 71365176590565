import './LandingPageCta.scss'

const LandingPageCta = () => {
  return (
    <div className='LandingPageCta'>

      <div
        className='d-flex justify-content-center align-items-center' style={{
          backgroundImage: "url('/images/landing-lean-agile-foundation/noNoiseCtaLp.png')",
          backgroundRepeat: 'no-repeat',
          minHeight: 'max(41.6vw, 350px)',
          backgroundSize: 'cover',
          backgroundPosition: '20% center',
          opacity: '1'
        }}
      >
        <div className='container'>

          <div className='row'>
            <div className='col-12 white-text text-center mt-5 '>
              <h1 className='blue display-1 '> ALCANCE ATÉ 10X MAIS RESULTADOS</h1>
              <h1 className=' white mb-4 display-4'> COM O MÉTODO QUE MUDOU O MUNDO</h1>
              <h3 className=''>
                <span className='white'>
                  {'Aprenda toda a essência do Lean e do Agile para transformar a sua organização e '}
                </span>
                <span className='blue'>
                  entregar mais valor para os seus clientes em menos tempo
                </span>
              </h3>
              <button
                onClick={() => {
                  window.location.href = 'https://academy.leanit101.com/leanandagilefoundationb2c'
                }}
                id='compre' className='LandingPageCta-buy mt-4' target='_blank'
              >
                <div className=''>
                  COMPRE AGORA
                </div>
              </button>

              <h3 className='white mt-2'>
                POR R$297,00
              </h3>
              <h5 className='white'> ou 12x de R$29,90 </h5>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPageCta
