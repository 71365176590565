import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AcademySalesPage from '../AcademySalesPage/AcademySalesPage'
import EbookPage from '../EbookPage/EbookPage'
// import EbookThankYouPage from '../EbookPage/EbookThankYouPage'
import AgilistaRaizPage from '../AgilistaRaizPage/AgilistaRaizPage'
import AgilistaRaizThankYouPage from '../AgilistaRaizPage/AgilistaRaizThankYouPage'
import VirtueArmyPage from '../VirtueArmyPage/VirtueArmyPage'
import EbookDownloadPage from '../EbookDownloadPage/EbookDownloadPage'
import LandingPage from '../LandingPage/LandingPage'
import AuthorsRightsPage from '../PoliciesPages/AuthorsRights'
import PrivacyPolicy from '../PoliciesPages/PrivacyPolicy'
import TermsOfUse from '../PoliciesPages/TermsOfUse'

const Router = ({ children }) => {
  return (
    <BrowserRouter>
      <Routes>
        {children}
        <Route path='/' element={<AcademySalesPage />} />
        <Route path='/lean-agile-foundation-training' element={<LandingPage />} />
        <Route path='/ebook' element={<EbookPage />} />
        {/* <Route path='/ebook-thankyou' element={<EbookThankYouPage />} /> */}
        <Route path='/agilista-raiz' element={<AgilistaRaizPage />} />
        <Route path='/agilista-raiz-thankyou' element={<AgilistaRaizThankYouPage />} />
        <Route path='/exercito-de-virtudes' element={<VirtueArmyPage />} />
        <Route path='/byBndWlhIGRlZmluaXRpdm8gZGEgdHJhbnNmb3JtYefjbyBsZWFuIGFnaWxl' element={<EbookDownloadPage />} />
        <Route path='/politica-de-protecao-aos-direitos-autorais' element={<AuthorsRightsPage />} />
        <Route path='/termos-de-uso' element={<TermsOfUse />} />
        <Route path='/politica-de-privacidade' element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
